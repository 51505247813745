import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { Mixpanel } from 'utils/mixpanel';
import { ERRORS_BLACKLIST } from 'components/ErrorBoundary/constants';

import '@atlaskit/css-reset';

import { version } from '../package.json';

import store from './redux/store';
import { connectUser } from './redux/auth/actions';
import Root from './Root';

const { OLY_ENABLE_SENTRY, NODE_ENV, SENTRY_DSN } = process.env;

// Init Sentry
if (SENTRY_DSN && OLY_ENABLE_SENTRY) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: version,
    ignoreErrors: ERRORS_BLACKLIST,
  });
}

// Init Mixpanel
Mixpanel.init();

const rootNode = document.getElementById('root');

async function init(target) {
  await store.dispatch(connectUser());

  ReactDOM.render(target, rootNode);
}

init(<Root store={store} />);
