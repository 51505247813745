import tinycolor from 'tinycolor2';
import { colors } from 'styles/common';
var getCustomColors = function (color, textColor, hoverColor) {
    var customColor = color;
    var customTextColor = textColor || colors.white;
    var customHoverColor = hoverColor || color;
    return {
        customColor: customColor,
        customTextColor: customTextColor,
        customHoverColor: tinycolor(customHoverColor)
            .saturate(10)
            .darken(10)
            .toString()
    };
};
var buttonColors = {
    primary: getCustomColors(colors.green),
    secondary: getCustomColors(colors.yellow),
    black: getCustomColors(colors.black),
    danger: getCustomColors(colors.red),
    grey: getCustomColors(colors.grey20, colors.grey100),
    basic: getCustomColors(colors.white, colors.grey100),
    link: getCustomColors('transparent', colors.grey100, 'transparent')
};
export var getButtonColors = function (appearance) {
    return buttonColors[appearance] || buttonColors.primary;
};
