var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colors, fonts, defaultFontsLibrary } from '../common';
var theme = {
    colors: colors,
    fonts: {
        primary: fonts.gilroy,
        secondary: fonts.poppins
    },
    fontTypes: __assign({}, defaultFontsLibrary),
    tag: {
        green: {
            bgColor: colors.lightGreen,
            borderColor: colors.green,
            textColor: colors.green
        },
        orange: {
            bgColor: colors.lightOrange,
            borderColor: colors.orange,
            textColor: colors.orange
        },
        blue: {
            bgColor: colors.lightenBlue,
            borderColor: colors.blue,
            textColor: colors.blue
        },
        grey: {
            bgColor: colors.grey40,
            borderColor: colors.darkBlue,
            textColor: colors.darkBlue
        }
    },
    button: {
        primary: {
            background: {
                "default": colors.orange
            },
            color: {
                "default": colors.white
            }
        },
        secondary: {
            background: {
                "default": colors.grey100
            },
            color: {
                "default": colors.white
            }
        },
        dark: {
            background: {
                "default": colors.black
            },
            color: {
                "default": colors.white
            }
        },
        danger: {
            background: {
                "default": colors.red
            },
            color: {
                "default": colors.white
            }
        },
        action: {
            background: {
                "default": colors.green,
                secondary: colors.lightGreen
            },
            color: {
                "default": colors.white,
                secondary: colors.green
            }
        },
        basic: {
            background: {
                "default": colors.white
            },
            color: {
                "default": colors.grey80
            }
        },
        success: {
            background: {
                "default": colors.green
            },
            color: {
                "default": colors.white
            }
        },
        error: {
            background: {
                "default": colors.red
            },
            color: {
                "default": colors.white
            }
        },
        grey: {
            background: {
                "default": colors.grey20
            },
            color: {
                "default": colors.grey100
            }
        },
        link: {
            background: {
                "default": 'inherit'
            },
            color: {
                "default": colors.grey100
            }
        },
        greyRounded: {
            background: {
                "default": colors.grey40
            },
            color: {
                "default": colors.grey100
            }
        }
    },
    field: {
        labelColor: colors.black,
        subLabelColor: colors.grey80,
        fontFamily: fonts.poppins
    },
    globalNav: {
        text: colors.white,
        background: colors.black
    },
    light: {
        on: {
            borderColor: colors.lightGreen,
            bgColor: colors.green
        },
        off: {
            borderColor: colors.lightRed,
            bgColor: colors.red
        },
        online: {
            borderColor: colors.lightGreen,
            bgColor: colors.green
        },
        offline: {
            borderColor: colors.lightRed,
            bgColor: colors.red
        },
        scheduled: {
            borderColor: colors.lightOrange,
            bgColor: colors.orange
        }
    },
    window: {
        breakpoint: 1024
    },
    modal: {
        width: '480px',
        height: '560px',
        largeWidth: '640px',
        largeHeight: '640px'
    },
    table: {
        height: '65vh'
    }
};
export default theme;
