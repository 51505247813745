import i18n from 'i18next';

export const NOT_DEFINED = i18n.t('zeus-common.notdefined');

// STATUS
export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const SCHEDULED = 'scheduled';

// DATES
export const DATE_MOMENT_FORMAT = 'llll';

export const DATE_LIST_FORMAT = 'YYYY-MM-DD';

export const DATE_PICKER_FORMAT = 'YYYY/MM/DD';
export const TIME_PICKER_FORMAT = 'HH:mm';

export const DATE_PICKER_PLACEHOLDER = 'YYYY/MM/DD';
export const TIME_PICKER_PLACEHOLDER = 'HH:MM';

// LANGUAGES
export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGES = ['en', 'fr', 'es'];
export const LANGUAGE_OPTIONS = [
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
];
export const LANGUAGE_COUNTRIES = {
  en: 'GB',
  fr: 'FR',
  es: 'ES',
};

export const ALL = 'ALL';

export const BASE_APP_IMAGES_URL =
  'https://sport-heroes-assets.s3.amazonaws.com/images/applications/small';

export const PLURAL = 'plural';

export const SINGULAR = 'singular';
