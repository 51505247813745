/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Map action to it's contained action type
 * @description it gets an object with at least a type property and returns it.
 * @example
 * getType({ type: 'TEST' }) //=> 'TEST'
 */
export function getType(action) {
    return action.type;
}
/**
 * Map action creator to it's contained action type
 * @description it gets an action creator that returns an object with at least a type property and returns it.
 * @example
 * const increment = (n: number) =>
  ({
    type: 'INCREMENT',
    payload: n,
  } as const);
 * getTypeFromActionCreator(increment) //=> 'INCREMENT'
 */
export function getTypeFromActionCreator(actionCreator) {
    if (!actionCreator().type) {
        throw new Error("Action creator that has been passed to getType() does not provide any API to expose action type.");
    }
    return getType(actionCreator());
}
export default getTypeFromActionCreator;
