var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import greyColors from '@sportheroes/oly-ds/components/UI/Colors';
import { defaultFonts } from '@sportheroes/oly-ds/components/UI/Fonts/defaultFonts';
export var colors = __assign(__assign({}, greyColors), { white: '#ffffff', green: '#7ed321', blue: '#00ABE5', red: '#F24949', orange: '#F8D064', purple: '#6E56E3', yellow: '#FEA932', lightenBlue: '#E8F5FC', lightRed: '#ffcccc', lightGreen: '#d4f5b1', lightOrange: '#FFF6E0', darkBlue: '#172B4D', darkRed: '#d63333', infoBlue: '#0099FF', formGrey: '#ECEBEF' });
export var fonts = {
    gilroy: 'Gilroy',
    poppins: 'Poppins'
};
export var defaultFontsLibrary = __assign({}, defaultFonts);
