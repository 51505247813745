import moment from 'moment-timezone';
import { OlyEngineApi } from '@sportheroes/oly-engine-api';
import { getDeviceId, getDeviceName, getCredentials, storeAccessToken, removeCredentials, } from './utils';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
var timezone = moment().tz(moment.tz.guess());
var _a = process.env, _b = _a.USERS_API_URL, USERS_API_URL = _b === void 0 ? '' : _b, _c = _a.CLIENT_ID, CLIENT_ID = _c === void 0 ? '' : _c;
var _d = (getCredentials === null || getCredentials === void 0 ? void 0 : getCredentials()) || {}, _e = _d.refreshToken, refreshToken = _e === void 0 ? '' : _e, _f = _d.accessToken, accessToken = _f === void 0 ? '' : _f;
export var baseHeaders = {
    'x-device-id': getDeviceId === null || getDeviceId === void 0 ? void 0 : getDeviceId(),
    'x-device-name': getDeviceName === null || getDeviceName === void 0 ? void 0 : getDeviceName(),
    timezone: timezone
};
export var baseTokenConfig = {
    tokenApiUrl: "".concat(USERS_API_URL, "/"),
    refreshToken: refreshToken,
    accessToken: accessToken,
    storeAccessToken: storeAccessToken,
    onLogout: removeCredentials,
    clientId: CLIENT_ID
};
export var olyEngineApi = new OlyEngineApi({
    headers: baseHeaders,
    tokenConfig: baseTokenConfig
});
