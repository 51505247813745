import React from 'react';

const Success = () => (
  <svg width="100%" height="100%" viewBox="-3 -1 15 8">
    <path
      d="M1.707 2.293A1 1 0 0 0 .293 3.707l2 2a1 1 0 0 0 1.414 0l4-4A1 1 0 1 0 6.293.293L3 3.586 1.707 2.293z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
);

export default Success;
