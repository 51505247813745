import ImagesApi from 'services/Images';
import { clientsCurrentIdSelector } from 'redux/clients/selectors';

export const uploadAsset = payload => {
  const { file, path, clientId, bucket = 'oly-assets' } = payload;

  if (file?.name) {
    const formattedName = file.name.replace(/[^a-zA-Z_.\d]/g, '');
    const filename = formattedName.replace(/^([^.]+)$|(\.[^.]+)$/i, `$1${Date.now()}$2`);

    const destination = `${path}/${filename}`;

    const data = new FormData();

    data.append('image', file);
    data.append('bucket', bucket);
    data.append('destination', destination);

    return ImagesApi.postImage({ clientId, payload: data }).then(p => p.request);
  }
};

export const uploadImage = payload => (dispatch, getState) => {
  const { file, destination, bucket = 'oly-assets' } = payload;

  // Get payload.clientId or clientsCurrent._id
  const clientId = payload.clientId || clientsCurrentIdSelector(getState());

  const data = new FormData();

  data.append('image', file);
  data.append('bucket', bucket);
  data.append('destination', destination);

  return ImagesApi.postImage({ clientId, payload: data }).then(p => p.request);
};

export const uploadImageFormated = ({ file, id, path, mandatoryFileName }) => dispatch => {
  // ^ a character excespt except [...]
  // a-z a single character in the range between a and z
  // A-Z a single character in the range between A and Z
  // _. matches a single character in the list _.
  // \d matches a digit (equal to [0-9])
  const formattedName = file.name.replace(/[^a-zA-Z_.\d]/g, '');
  const filename = mandatoryFileName
    ? `${mandatoryFileName}.${formattedName.split('.').pop()}`
    : formattedName.replace(/^([^.]+)$|(\.[^.]+)$/i, `$1${Date.now()}$2`);

  const destination = id ? `${path}/${id}/${filename}` : `${path}/${filename}`;

  return dispatch(
    uploadImage({
      file,
      destination,
    }),
  );
};
