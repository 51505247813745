import {
  FETCH_USERS_PENDING,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  RESET_USERS,
} from './constants';

const INITIAL_STATE = {
  data: null,
  hasMore: true,
  isLoading: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        hasMore: action.payload.hasMore,
        isLoading: false,
        error: null,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case RESET_USERS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
