import get from 'lodash/get';
import i18n from 'i18next';
import moment from 'moment';

import { challengeUtils } from '@sportheroes/oly-engine-utils';

import { ONLINE, OFFLINE, SCHEDULED } from 'constants/challenge';

import { hasOnlyNumber, isSlugValid, isHexColor } from './form';

export const format = (number, options = {}) => {
  const language = options.language || i18n.language || 'fr';

  try {
    return Intl.NumberFormat(language, options).format(number || 0);
  } catch (e) {
    return e;
  }
};

/**
 * Return the challenge display state, can be OFFLINE ONLINE SCHEDULED
 * @param {Object} challenge
 */
export const getChallengeState = challenge => {
  const isOnline = get(challenge.general, 'isOnline.value');
  const isHidden = get(challenge.general, 'isHidden.value');
  const onlineStartDate = get(challenge.general, 'onlineStartDate.value');
  const now = moment();

  if (isOnline) {
    return ONLINE;
  }

  if (!isHidden && moment(onlineStartDate) > now) {
    return SCHEDULED;
  }

  return OFFLINE;
};

export const getChallengeStatus = challenge => {
  const start = challenge.timeframe ? challenge.timeframe.start : challenge.general.start.value;
  const end = challenge.timeframe ? challenge.timeframe.end : challenge.general.end.value;

  const status = challengeUtils.getChallengeTimeframeStatus({ start, end });

  return i18n.t(`zeus-challenges.status.${status}`);
};

export const getChallengeType = ({ group, isClientChallenge, user }, tags) => {
  if (tags && tags[0] === 'instantWin') {
    return 'instantWin';
  }

  if (user) {
    return 'solo';
  }

  if (isClientChallenge) {
    return 'charity';
  }

  if (group) {
    return 'group';
  }

  return 'unknown';
};

export const conditionTypes = [
  'minDistance',
  'minDuration',
  'minPoints',
  'minDescent',
  'minClimb',
  'maxDistance',
  'maxDuration',
  'maxPoints',
  'maxDescent',
  'maxClimb',
  'repeat',
  'perDay',
  'attempt',
  'noObjective',
  'unit',
];

export const noObjectivesOptions = [
  { value: 'distance', label: 'Kilometers' },
  { value: 'points', label: 'Points' },
  { value: 'duration', label: 'Duration' },
  { value: 'climb', label: 'Climb' },
  { value: 'descent', label: 'Descent' },
];

export const validationFields = {
  general: {
    referenceValidate: value => value && value.length && value.length >= 2,
    slugValidate: value => isSlugValid(value),
  },
  objectives: {
    conditionValidate: value => hasOnlyNumber(value),
  },
  charity: {
    moneyMultiplierValidate: value => hasOnlyNumber(value),
  },
  tagColor: {
    colorValidate: value => (value ? isHexColor(value) : true),
  },
  instantWin: {
    numberprizesValidate: value => hasOnlyNumber(value),
    startAvailableAtValidate: (value, challengeStartDate, challengeEndDate) =>
      moment(value).isBetween(challengeStartDate, challengeEndDate, null, '[]'),
    endAvailableAtValidate: (value, challengeStartDate, challengeEndDate) =>
      moment(value).isBetween(challengeStartDate, challengeEndDate, null, '[]'),
  },
};
