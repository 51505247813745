var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var initialState = {
    userClients: null,
    currentAdminUserClients: null,
    currentUserClientId: null,
    error: false,
    currentUserClient: null
};
var reducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS':
        case 'OLY_LOAD_ONE_USER_CLIENT':
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS':
            return __assign(__assign({}, state), { error: initialState.error });
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_SUCCESS':
            return __assign(__assign({}, state), { userClients: action.payload.userClients });
        case 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_ERROR':
        case 'OLY_LOAD_ONE_USER_CLIENT_ERROR':
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS_ERROR':
            return __assign(__assign({}, state), { error: action.payload.error });
        case 'OLY_ENGINE_STORE_SET_CURRENT_USER_CLIENT_ID':
            return __assign(__assign({}, state), { currentUserClientId: action.payload.clientId });
        case 'OLY_ENGINE_STORE_RESET_USER_CLIENTS':
            return initialState;
        case 'OLY_LOAD_ONE_USER_CLIENT_SUCCESS':
            return __assign(__assign({}, state), { currentUserClient: action.payload.userClient });
        case 'OLY_UPDATE_USER_CLIENT_SETTINGS_SUCCESS':
            return __assign(__assign({}, state), { currentUserClient: __assign(__assign({}, state.currentUserClient), { settings: __assign(__assign({}, (_a = state.currentUserClient) === null || _a === void 0 ? void 0 : _a.settings), action.payload.settings) }) });
        default:
            return state;
    }
};
export default reducer;
