import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
export var userFeedStateSelector = createSelector(userStateSelector, function (user) { return user.feed; });
export var userFeedSelector = createSelector(userFeedStateSelector, function (feed) { return feed.userFeed; });
export var userFeedDataSelector = createSelector(userFeedSelector, function (userFeed) { return (userFeed ? userFeed.data : null); });
var userFeedMetaSelector = createSelector(userFeedSelector, function (userFeed) { return (userFeed ? userFeed.meta : null); });
export var userFeedNextSelector = createSelector(userFeedMetaSelector, function (userFeedMeta) { return (userFeedMeta ? userFeedMeta.next : null); });
export var userFeedhasNextSelector = createSelector(userFeedMetaSelector, function (userFeedMeta) { return (userFeedMeta ? userFeedMeta.hasNext : null); });
export var userFeedErrorSelector = createSelector(userFeedStateSelector, function (feed) { return feed.error; });
export default {
    userFeedDataSelector: userFeedDataSelector,
    userFeedMetaSelector: userFeedMetaSelector,
    userFeedNextSelector: userFeedNextSelector,
    userFeedhasNextSelector: userFeedhasNextSelector,
    userFeedErrorSelector: userFeedErrorSelector
};
