import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

const Container = styled.div`
  bottom: 0;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
`;

const NotificationProgressContainer = styled(animated.div)`
  background: ${p => p.color};
  bottom: 0;
  position: absolute;
  top: 0;
`;

const NotificationProgressBar = ({ duration, color }) => {
  const animatedProps = useSpring({
    to: { width: 100 },
    from: { width: 0 },
    config: { duration },
  });

  return (
    <Container>
      <NotificationProgressContainer
        color={color}
        style={{ width: animatedProps.width.to(width => `${width}%`) }}
      />
    </Container>
  );
};

export default NotificationProgressBar;
