import React from 'react';

const Info = () => (
  <svg viewBox="0 0 4 10" width="100%" height="100%">
    <path
      d="M2.188.75a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm1.687 10.125h-3a.75.75 0 1 1 0-1.5h.563a.187.187 0 0 0 .187-.188V5.813a.188.188 0 0 0-.188-.188H.875a.75.75 0 0 1 0-1.5h.75a1.5 1.5 0 0 1 1.5 1.5v3.563c0 .103.084.187.188.187h.562a.75.75 0 1 1 0 1.5z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default Info;
