import baseProduct from './baseProduct';

export default ({ countryValues = {} } = {}) => ({
  activities: {
    excludeDaily: true,
  },
  general: {
    reference: {
      value: null,
    },
    slug: {
      value: null,
    },
    type: {
      value: null,
      isDisabled: false,
    },
    start: {
      value: null,
    },
    end: {
      value: null,
    },
    onlineStartDate: {
      value: null,
    },
    onlineEndDate: {
      value: null,
    },
    leaderboard: {
      isApplicableToMsRanking: false,
      isCrossGroupChallenge: false,
    },
    isHidden: {
      value: true,
    },
    membersOf: {
      value: [],
    },
    excludedFrom: {
      value: [],
    },
    isCodeRestricted: {
      value: false,
    },
    restrictedAccessCodes: {
      value: [],
    },
    challengeType: {
      value: null,
    },
  },
  highlight: {
    highlight: {
      value: false,
    },
    highlightCta: {
      value: { ...countryValues },
    },
    highlightBackgroundImage: {
      url: null,
      file: null,
    },
  },
  promote: {
    promote: {
      value: false,
    },
    promoteText: {
      value: { ...countryValues },
    },
    promoteDesktop: {
      url: null,
      file: null,
    },
    promoteMobile: {
      url: null,
      file: null,
    },
    promoteLogo: {
      url: null,
      file: null,
    },
  },
  objectives: {
    combinations: {
      value: [
        {
          conditions: [{ key: 'attempt', value: 1, shortId: 'default' }],
          types: [],
          shortId: 'default',
        },
      ],
    },
    objective: {
      value: { ...countryValues },
    },
  },
  charity: {
    moneyMultiplier: {
      value: null,
    },
    hashtag: {
      value: null,
    },
    rightIllu: {
      url: null,
      file: null,
    },
    headerLogo: {
      url: null,
      file: null,
    },
    modalConfirm: {
      url: null,
      file: null,
    },
  },
  group: {
    usersAutoRegistration: {
      value: false,
    },
    hashtag: {
      value: null,
    },
    headerLogo: {
      url: null,
      file: null,
    },
    modalConfirm: {
      url: null,
      file: null,
    },
  },
  instantWin: {
    remaining: {
      value: null,
    },
    available: {
      value: null,
    },
    numberprizes: {
      value: null,
    },
    startAvailableAt: {
      value: null,
    },
    endAvailableAt: {
      value: null,
    },
  },
  content: {
    title: {
      value: { ...countryValues },
    },
    slug: {
      value: { ...countryValues },
    },
    type: {
      value: { ...countryValues },
    },
    excerpt: {
      value: { ...countryValues },
    },
    content: {
      value: { ...countryValues },
    },
    rewardName: {
      value: { ...countryValues },
    },
    rewardConditions: {
      value: { ...countryValues },
    },
  },
  customTagColor: {
    value: undefined,
  },
  images: {
    // Image couverture
    cover: {
      url: null,
      file: null,
    },
    // Image card
    indexCover: {
      url: null,
      file: null,
    },
    // Image badge
    badge: {
      url: null,
      file: null,
    },
    // Image du prix à gagner
    rewardCover: {
      url: null,
      file: null,
    },
  },
  emails: {
    finishers: {
      headerImage: {
        url: null,
        file: null,
      },
      content: {
        value: { ...countryValues },
      },
      ctaText: {
        value: { ...countryValues },
      },
      ctaLink: {
        value: { ...countryValues },
      },
    },
    finishersPush: {
      title: {
        value: {
          ...countryValues,
          fr: 'Bravo ! Challenge validé !',
          en: 'Yay! You finished the challenge.',
        },
      },
      message: {
        value: {
          ...countryValues,
          fr: 'Decouvrez si vous avez gagné 🎁',
          en: "Check to see if you've won 🎁",
        },
      },
    },
    winners: {
      headerImage: {
        url: null,
        file: null,
      },
      content: {
        value: { ...countryValues },
      },
      ctaText: {
        value: { ...countryValues },
      },
      ctaLink: {
        value: { ...countryValues },
      },
    },
    registration: {
      headerImage: {
        url: null,
        file: null,
      },
      content: {
        value: { ...countryValues },
      },
      ctaText: {
        value: { ...countryValues },
      },
      ctaLink: {
        value: { ...countryValues },
      },
    },
  },
  bonus: {
    activated: {
      value: false,
    },
    points: { value: null },
  },
  crm: {
    hasEmailFinishers: { value: false },
    hasPushFinishers: { value: false },
    hasEmailWinners: { value: false },
    hasEmailRegistration: { value: false },
  },
  products: [baseProduct({ countryValues })],
  segmentation: {
    isEnabled: false,
    segments: [],
  },
});
