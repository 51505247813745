import { all } from 'redux-saga/effects';

import {
  activitiesSagas,
  rankingsSagas,
  rewardsSagas,
  couponsSagas,
  challengesSagas,
  collectionsSagas,
  blogSagas,
  segmentsSagas,
} from '@sportheroes/oly-engine-store';

import RankingsService from 'services/Rankings';
import PaymentsService from 'services/Payments';
import UsersService from 'services/Users';
import UsersCoreService from 'services/UsersCore';
import FeedService from 'services/Feed';
import AppsService from 'services/Apps';
import ActivitiesService from 'services/Activities';
import ChallengesService from 'services/Challenges';
import RewardsService from 'services/Rewards';
import CollectionsService from 'services/Collections';
import BlogService from 'services/Blog';
import SegmentsService from 'services/Segments';
import GroupsApi from 'services/Groups';
import { userInfoSagas } from 'redux/user/info';
import { userCoreSagas } from 'redux/user/core';
import { userChallengesSagas } from 'redux/user/challenges';
import { userFeedSagas } from 'redux/user/feed';
import { userAppsSagas } from 'redux/user/apps';
import { userActivitiesSagas } from 'redux/user/activities';
import { userClientsSagas } from 'redux/user/clients';
import { userRewardsSagas } from 'redux/user/rewards';

export default function* sagas() {
  yield all([
    ...activitiesSagas(ActivitiesService),
    ...rankingsSagas(RankingsService),
    ...couponsSagas(PaymentsService),
    ...userInfoSagas(UsersService),
    ...userCoreSagas(UsersCoreService),
    ...userChallengesSagas({ userApi: UsersCoreService, groupsApi: GroupsApi }),
    ...userFeedSagas(FeedService),
    ...userAppsSagas(AppsService),
    ...userActivitiesSagas(ActivitiesService),
    ...userClientsSagas(UsersService),
    ...challengesSagas({ challengeApi: ChallengesService, userCoreApi: UsersCoreService }),
    ...rewardsSagas(RewardsService),
    ...userRewardsSagas(UsersCoreService),
    ...collectionsSagas(CollectionsService),
    ...blogSagas(BlogService),
    ...segmentsSagas(SegmentsService),
  ]);
}
