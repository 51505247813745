import {
  LOGIN,
  LOGOUT,
  RESET_AVAILABLE_CLIENTS,
  SET_AVAILABLE_CLIENTS,
  SET_USER_ROLES,
} from './constants';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
  roles: [],
  availableClients: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, user: action.payload.user, isAuthenticated: true };
    case SET_USER_ROLES:
      return { ...state, roles: action.payload.roles, isAuthenticated: true };
    case SET_AVAILABLE_CLIENTS:
      return { ...state, availableClients: action.payload.availableClients };
    case RESET_AVAILABLE_CLIENTS:
      return { ...state, availableClients: INITIAL_STATE.availableClients };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
