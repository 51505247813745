import { NOTIFICATION_SET, NOTIFICATION_UNSET } from './constants';

const INITIAL_STATE = {
  data: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_SET:
      return {
        ...state,
        data: action.payload.isImportant
          ? [action.payload.data, ...state.data]
          : [...state.data, action.payload.data],
      };
    case NOTIFICATION_UNSET:
      return {
        ...state,
        data: state.data.filter(item => item.id !== action.payload.notificationId),
      };
    default:
      return state;
  }
};

export default reducer;
