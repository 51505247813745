export const CREATE_CHALLENGE_SUCCESS = 'CREATE_CHALLENGE_SUCCESS';
export const CREATE_CHALLENGE_ERROR = 'CREATE_CHALLENGE_ERROR';

export const EDIT_CHALLENGE = 'EDIT_CHALLENGE';
export const EDIT_CHALLENGE_ERROR = 'EDIT_CHALLENGE_ERROR';
export const EDIT_CHALLENGE_SUCCESS = 'EDIT_CHALLENGE_SUCCESS';

export const EDIT_CHALLENGE_STATE = 'EDIT_CHALLENGE_STATE';
export const EDIT_CHALLENGE_STATE_ERROR = 'EDIT_CHALLENGE_STATE_ERROR';
export const EDIT_CHALLENGE_STATE_SUCCESS = 'EDIT_CHALLENGE_STATE_SUCCESS';

export const FETCH_CHALLENGES_ERROR = 'FETCH_CHALLENGES_ERROR';
export const FETCH_CHALLENGES_PENDING = 'FETCH_CHALLENGES_PENDING';
export const FETCH_CHALLENGES_SUCCESS = 'FETCH_CHALLENGES_SUCCESS';

export const RESET_CHALLENGES = 'RESET_CHALLENGES';

export const DELETE_CHALLENGE_SUCCESS = 'DELETE_CHALLENGE_SUCCESS';
export const DELETE_CHALLENGE_ERROR = 'DELETE_CHALLENGE_ERROR';

export const EDIT_CHALLENGE_PRIZES_ERROR = 'EDIT_CHALLENGE_PRIZES_ERROR';

export const EDIT_CHALLENGE_CODES_ERROR = 'EDIT_CHALLENGE_CODES_ERROR';

export const EDIT_CHALLENGE_BONUS_ERROR = 'EDIT_CHALLENGE_BONUS_ERROR';

export const DISPATCH_PRODUCTS_ERROR = 'DISPATCH_PRODUCTS_ERROR';
