import { createSelector } from 'reselect';
import get from 'lodash/get';

const challengesSelector = state => get(state, 'challengesLegacy');

export const challengesDataSelector = createSelector(challengesSelector, challenges =>
  get(challenges, 'data'),
);

export const challengesHasMoreSelector = createSelector(challengesSelector, challenges =>
  get(challenges, 'hasMore'),
);

export const challengesCountSelector = createSelector(challengesSelector, challenges =>
  get(challenges, 'totalQuery'),
);

export const challengesIsLoadingSelector = createSelector(challengesSelector, challenges =>
  get(challenges, 'isLoading'),
);

export const challengesErrorSelector = createSelector(challengesSelector, challenges =>
  get(challenges, 'error'),
);
