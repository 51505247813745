var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from '../../utils';
import actions from './actions';
export function loadUserInfo(api, action) {
    var _a, userId, clientId, _b, withGroup, user, error_1;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, _b = _a.withGroup, withGroup = _b === void 0 ? false : _b;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .getUser({
                        id: userId,
                        clientId: clientId,
                        withGroup: withGroup
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                user = _c.sent();
                return [4 /*yield*/, put(actions.loadUserInfoSuccess(user))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                error_1 = _c.sent();
                return [4 /*yield*/, put(actions.loadUserInfoError(error_1))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function editUserInfo(api, action) {
    var _a, userId, clientId, profile, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, profile = _a.profile;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .editUserProfile({
                        id: userId,
                        clientId: clientId,
                        profile: profile
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.editUserInfoSuccess(userId))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_2 = _b.sent();
                return [4 /*yield*/, put(actions.editUserInfoError(error_2))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function editUserClient(api, action) {
    var _a, userId, clientId, settings, metadata, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, settings = _a.settings, metadata = _a.metadata;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .editUserClient({
                        id: userId,
                        clientId: clientId,
                        settings: settings,
                        metadata: metadata
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.editUserClientSuccess())];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.editUserClientError(error_3))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function deleteUserInfoGroup(api, action) {
    var _a, userId, groupId, clientId, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, groupId = _a.groupId, clientId = _a.clientId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .deleteUserGroup({
                        userId: userId,
                        groupId: groupId,
                        clientId: clientId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.deleteUserInfoGroupSuccess(groupId))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_4 = _b.sent();
                return [4 /*yield*/, put(actions.deleteUserInfoGroupError(error_4))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function deleteUserInfoClient(api, action) {
    var _a, userId, clientId, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .deleteUserClient({
                        userId: userId,
                        clientId: clientId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.deleteUserInfoClientSuccess(clientId))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_5 = _b.sent();
                return [4 /*yield*/, put(actions.deleteUserInfoClientError(error_5))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function deleteUser(api, action) {
    var userId, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userId = action.payload.userId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api.deleteUser(userId).then(function (p) { return p.request; })];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(actions.deleteUserSuccess(userId))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_6 = _a.sent();
                return [4 /*yield*/, put(actions.deleteUserError(error_6))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function watchLoadUserInfo(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserInfo), loadUserInfo, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchEditUserInfo(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.editUserInfo), editUserInfo, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchEditUserClient(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.editUserClient), editUserClient, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchDeleteUserInfoGroup(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.deleteUserInfoGroup), deleteUserInfoGroup, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchDeleteUserInfoClient(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.deleteUserInfoClient), deleteUserInfoClient, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchDeleteUser(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.deleteUser), deleteUser, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userSagas = function (api) { return [
    fork(watchLoadUserInfo, api),
    fork(watchEditUserInfo, api),
    fork(watchEditUserClient, api),
    fork(watchDeleteUserInfoGroup, api),
    fork(watchDeleteUserInfoClient, api),
    fork(watchDeleteUser, api),
]; };
export default userSagas;
