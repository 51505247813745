var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FETCH_CURRENT_GROUP_SUCCESS, FETCH_CURRENT_GROUP_PENDING, FETCH_CURRENT_GROUP_ERROR, RESET_CURRENT_GROUP, } from './constants';
var INITIAL_STATE = {
    currentGroup: null,
    isLoading: false,
    error: false
};
var reducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case FETCH_CURRENT_GROUP_SUCCESS:
            return {
                currentGroup: action.payload,
                isLoading: false,
                error: false
            };
        case FETCH_CURRENT_GROUP_PENDING:
            return __assign(__assign({}, state), { isLoading: action.payload });
        case FETCH_CURRENT_GROUP_ERROR:
            return {
                currentGroup: null,
                isLoading: false,
                error: true
            };
        case RESET_CURRENT_GROUP:
            return INITIAL_STATE;
        default:
            return state;
    }
};
export default reducer;
