import get from 'lodash/get';
import * as Sentry from '@sentry/browser';
import compact from 'lodash/compact';
import moment from 'moment';

import ChallengesService from 'services/Challenges';
import InstantWinService from 'services/InstantWin';
import BonusService from 'services/Bonus';
import PaymentsService from 'services/Payments';
import {
  clientsCurrentIdSelector,
  clientsCurrentSupportedLanguagesSelector,
  defaultLanguageSelector,
} from 'redux/clients/selectors';
import { uploadImageFormated } from 'redux/images/actions';
import { challengeToForm } from 'components/FetchData/challengeToForm';
import { INSTANT_WIN } from 'constants/challenge';

import {
  FETCH_CHALLENGES_PENDING,
  FETCH_CHALLENGES_SUCCESS,
  FETCH_CHALLENGES_ERROR,
  RESET_CHALLENGES,
  EDIT_CHALLENGE_STATE_SUCCESS,
  EDIT_CHALLENGE_STATE_ERROR,
  DELETE_CHALLENGE_SUCCESS,
  DELETE_CHALLENGE_ERROR,
  EDIT_CHALLENGE_PRIZES_ERROR,
  EDIT_CHALLENGE_CODES_ERROR,
  EDIT_CHALLENGE_BONUS_ERROR,
  DISPATCH_PRODUCTS_ERROR,
  EDIT_CHALLENGE_SUCCESS,
  CREATE_CHALLENGE_SUCCESS,
} from './constants';

const DEFAULT_LIMIT = 20;

export const fetchChallenges = ({
  groupId,
  clientId,
  filters,
  sorting,
  skip = null,
  fetchMore = false,
}) => async (dispatch, getState) => {
  const currentClientId = clientId || clientsCurrentIdSelector(getState());
  const challengesLength = (getState().challengesLegacy.data || []).length;
  const languages = clientsCurrentSupportedLanguagesSelector(getState());
  const search = get(filters, 'search');
  const groups = get(filters, 'groups');
  const status = get(filters, 'status');
  const types = get(filters, 'types');

  const defaultLanguage = defaultLanguageSelector(getState());

  dispatch({ type: FETCH_CHALLENGES_PENDING });

  try {
    const { data, meta } = await ChallengesService.getChallengesAdmin({
      clientId: currentClientId,
      skip: skip || (fetchMore ? challengesLength : 0),
      limit: DEFAULT_LIMIT,
      search,
      sort: sorting,
      groupId: groupId || groups,
      timeframe: status,
      type: types,
    }).then(p => p.request);

    const formattedChallenges = Array.isArray(data)
      ? data.map(item => challengeToForm(languages, item, defaultLanguage))
      : [];

    const newData = fetchMore
      ? [...(getState().challengesLegacy.data || []), ...formattedChallenges]
      : formattedChallenges;

    return dispatch({
      type: FETCH_CHALLENGES_SUCCESS,
      payload: { data: newData, hasMore: meta?.hasNext, totalQuery: meta?.totalQuery },
    });
  } catch (error) {
    return dispatch({ type: FETCH_CHALLENGES_ERROR, payload: { error } });
  }
};

export const resetChallenges = () => ({
  type: RESET_CHALLENGES,
});

// return data without dispatch
export const fetchChallenge = async (payload, options = {}) => {
  let instantWin = { data: {} };
  const { challengeId, clientId } = payload;
  const { withChallenge = true, withChallengeStatus = true } = options;

  try {
    const promises = [
      withChallenge &&
        ChallengesService.getChallenge({ challengeId, clientId }).then(p => p.request),
      withChallengeStatus &&
        ChallengesService.getChallengeStatus({ challengeId, clientId }).then(p => p.request),
    ];

    const [challenge, challengeStatus] = await Promise.all(promises);

    const { challengeType } = challenge;

    if (challengeType === INSTANT_WIN) {
      // Get the numberOfPrizes for instantWin challenge
      instantWin = await InstantWinService.getPrizesCount({ challengeId: challenge._id }).then(
        ({ request }) => request,
      );
    }

    const products = await ChallengesService.getChallengeProducts({
      challengeId: challenge._id,
    }).then(({ request }) => request);

    let bonusConfig;

    try {
      bonusConfig = await BonusService.getBonus({
        clientId,
        resourceType: 'challenge',
        resourceId: challenge._id,
        enabled: true,
      }).then(({ request }) => request);
    } catch (error) {
      bonusConfig = {
        _id: null,
        points: null,
        enabled: false,
      };
    }

    const result = {
      data: {
        ...(withChallenge && {
          challenge: {
            ...challenge,
            instantWin: instantWin.data,
            products,
            bonusConfig,
          },
        }),
        ...(withChallengeStatus && { challengeStatus }),
      },
      error: null,
    };

    return result;
  } catch (error) {
    const result = {
      data: null,
      error: {
        code: 500,
        message: 'global_error',
      },
    };
    return result;
  }
};

// update instant wins and get a prize list in return
export const updateInstantWin = async (challengeId, clientId, payload) => {
  try {
    return await InstantWinService.updateInstantWin({
      challengeId,
      clientId,
      payload,
    });
  } catch ({ message }) {
    return { error: message };
  }
};

// Update challenges codes
export const updateChallengeCodes = async (challengeId, payloadAccessCodes) => {
  let i;
  const chunk = 1000;
  for (i = 0; i < payloadAccessCodes.length; i += chunk) {
    // eslint-disable-next-line no-await-in-loop
    await ChallengesService.updateChallengeCodes({
      challengeId,
      payload: payloadAccessCodes.slice(i, i + chunk),
    }).then(({ request }) => request);
  }
};

export const createBonus = (challengeId, clientId, payloadBonus) => {
  return BonusService.createBonus({
    clientId,
    payload: {
      ...payloadBonus,
      name: 'UserChallengeCompleted',
      resourceId: challengeId,
      resourceType: 'challenge',
    },
  });
};

async function runPromisesInSequence(promises) {
  // eslint-disable-next-line  no-restricted-syntax
  for (const promise of promises) {
    // eslint-disable-next-line no-await-in-loop
    await promise();
  }
}

// Create or update products associated to a challenge
export const dispatchProducts = async (challengeId, clientId, payloadProducts) => {
  const promises = payloadProducts
    .map(product => {
      product.resourceId = challengeId;
      product.clientId = clientId;

      if (product._id) {
        return () =>
          PaymentsService.updateProduct({
            productId: product._id,
            payload: product,
          })
            .then(({ request }) => request)
            .then(response => response);
      }

      if (product.identifier) {
        const { shortId, ...newProduct } = product;

        return () =>
          PaymentsService.createProduct({
            payload: newProduct,
          })
            .then(({ request }) => request)
            .then(response => response);
      }

      return null;
    })
    .filter(promise => promise !== null);

  if (promises.length) {
    await runPromisesInSequence(promises);
  }

  return null;
};

// generic edit challenge action (should not be called in a component )
export const editChallenge = ({
  challengeId,
  clientId,
  payload,
  shouldRefetchAfterEdit = true,
  payloadInstantWin = null,
  payloadAccessCodes = null,
  payloadProducts = null,
  payloadBonus = null,
  bonus = null,
}) => async dispatch => {
  await ChallengesService.editChallenge({ challengeId, clientId, payload });

  // Send a new code list if it has been updated
  if (payloadAccessCodes !== null) {
    try {
      await updateChallengeCodes(challengeId, payloadAccessCodes);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: EDIT_CHALLENGE_CODES_ERROR, payload: { error } });
    }
  }

  if (payloadProducts !== null) {
    try {
      await dispatchProducts(challengeId, clientId, payloadProducts);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: DISPATCH_PRODUCTS_ERROR, payload: { error } });
    }
  }

  // Create new bonus for this challenge
  if (bonus && !bonus._id && bonus.activated.value) {
    try {
      await createBonus(challengeId, clientId, payloadBonus).then(({ request }) => request);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: EDIT_CHALLENGE_BONUS_ERROR, payload: { error } });
    }
  }

  // Delete exsiting bonus for this challenge
  if (bonus && bonus._id && !bonus.activated.value) {
    try {
      await BonusService.deleteBonus({ bonusConfigId: bonus._id }).then(({ request }) => request);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: EDIT_CHALLENGE_BONUS_ERROR, payload: { error } });
    }
  }
  // Update exsiting bonus for this challenge
  if (bonus && bonus._id && bonus.activated.value) {
    try {
      await BonusService.updateBonus({
        clientId,
        bonusConfigId: bonus._id,
        payload: payloadBonus,
      }).then(({ request }) => request);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: EDIT_CHALLENGE_BONUS_ERROR, payload: { error } });
    }
  }

  if (!shouldRefetchAfterEdit) {
    return;
  }

  // Get the client challenges, to get the updates of the edited challenge
  await dispatch(fetchChallenges({ clientId }));

  // Only update instant win if the challenge is not yet started
  if (payloadInstantWin && payload.timeframe.start > moment().toISOString()) {
    const updateInstantWinPayload = {
      numberOfPrizes: payloadInstantWin.numberOfPrizes,
      timeframe: {
        startAvailableAt: payloadInstantWin.timeframe.startAvailableAt,
        endAvailableAt: payloadInstantWin.timeframe.endAvailableAt,
      },
    };

    try {
      await updateInstantWin(challengeId, clientId, updateInstantWinPayload);
    } catch (error) {
      Sentry.captureException(error);
      return dispatch({ type: EDIT_CHALLENGE_PRIZES_ERROR, payload: { error } });
    }
  }

  return dispatch({
    type: EDIT_CHALLENGE_SUCCESS,
    payload: {
      challengeId,
    },
  });
};

// specific edit challenge action
export const editChallengeState = ({ challengeId, isHidden }) => async (dispatch, getState) => {
  const currentClientId = clientsCurrentIdSelector(getState());

  try {
    dispatch(
      editChallenge({
        challengeId,
        clientId: currentClientId,
        payload: { isHidden, client: currentClientId },
        shouldRefetchAfterEdit: false,
      }),
    );

    return dispatch({ type: EDIT_CHALLENGE_STATE_SUCCESS, payload: { challengeId, isHidden } });
  } catch (error) {
    Sentry.captureException(error);
    return dispatch({ type: EDIT_CHALLENGE_STATE_ERROR });
  }
};

export const uploadImageChallenge = ({ file, challengeId }) => {
  return uploadImageFormated({ file, id: challengeId, path: 'challenges' });
};

export const uploadImageProduct = file => {
  return uploadImageFormated({ file, path: 'products' });
};

const uploadImagesChallenge = ({ images, challengeId }) => dispatch => {
  return Object.keys(images).map(name => {
    if (images[name].file) {
      return uploadImageChallenge({
        file: images[name].file,
        challengeId,
      })(dispatch).then(res => ({ [name]: res.uri }));
    }
    return null;
  });
};

const parseEditedImages = ({ display, languages, images, defaultLanguage }) => {
  const displayTypes = ['finishers', 'nonWinners', 'winners', 'finishersPush'];
  const newDisplayByLanguages = languages.reduce((accumulateur, lang) => {
    const crm = displayTypes.reduce((acc, curr) => {
      acc[curr] = { ...display[lang].crm[curr] };
      return acc;
    }, {});

    accumulateur[lang] = {
      ...display[lang],
      ...images,
      rightIllu: { image: images.rightIllu },
      modalConfirm: { image: images.modalConfirm },
      crm,
    };
    return accumulateur;
  }, {});

  return {
    ...display,
    ...newDisplayByLanguages,
    ...newDisplayByLanguages[defaultLanguage],
  };
};

export const createChallenge = ({
  challengeId,
  clientId,
  payload,
  payloadInstantWin,
  payloadAccessCodes,
  payloadBonus,
  payloadProducts,
  bonus,
  duplicating,
  images,
  languages,
  defaultLanguage,
}) => async dispatch => {
  let id;
  return ChallengesService.createChallenge({ clientId, payload })
    .then(p => p.request)
    .then(res =>
      Promise.all(
        compact(
          uploadImagesChallenge({
            images,
            challengeId: res._id,
          })(dispatch),
        ),
      ).then(response => {
        id = res._id;

        const images = response.reduce((a, v) => ({ ...a, ...v }), {});
        const displayWithUploadedImages = parseEditedImages({
          display: res.display,
          languages,
          images,
          defaultLanguage,
        });

        return dispatch(
          editChallenge({
            challengeId: id,
            clientId,
            payloadAccessCodes,
            payloadProducts,
            payload: {
              display: displayWithUploadedImages,
            },
          }),
        );
      }),
    )
    .then(() => {
      if (payloadInstantWin) {
        return InstantWinService.createInstantWin({
          clientId,
          payload: {
            ...payloadInstantWin,
            policies: { redeemOnChallengeSuccess: [id], redeem: 'ON_CHALLENGE_SUCCESS' },
          },
        })
          .then(({ request }) => request)
          .then(() => id);
      }
      return id;
    })
    .then(id => {
      if (bonus && bonus.activated.value && (!challengeId || duplicating)) {
        return BonusService.createBonus({
          clientId,
          payload: {
            ...payloadBonus,
            name: 'UserChallengeCompleted',
            resourceId: id,
            resourceType: 'challenge',
          },
        })
          .then(({ request }) => request)
          .then(() => id);
      }
      return id;
    })
    .then(id => dispatch({ type: CREATE_CHALLENGE_SUCCESS, payload: { challengeId: id } }));
};

export const getAllInstantwinPrizes = async ({ challengeId, clientId, limit, skip }) => {
  try {
    const { data, meta } = await InstantWinService.getAllPrizes({
      challengeId,
      clientId,
      limit,
      skip,
    }).then(({ request }) => request);
    const { hasNext, skip: nextSkip } = meta;
    return { data, hasNext, skip: nextSkip };
  } catch ({ message }) {
    return { error: message };
  }
};

export const deleteChallenge = ({ challengeId, clientId }) => async dispatch => {
  try {
    await ChallengesService.deleteChallenge({ challengeId, clientId }).then(p => p.request);
    return dispatch({ type: DELETE_CHALLENGE_SUCCESS, payload: { challengeId } });
  } catch (error) {
    Sentry.captureException(error);
    return dispatch({ type: DELETE_CHALLENGE_ERROR, payload: { error } });
  }
};
