var uploadProfilePicture = function (payload) {
    return ({
        type: 'OLY_UPLOAD_PROFILE_PICTURE',
        payload: payload
    });
};
var uploadProfilePictureSuccess = function () {
    return ({
        type: 'OLY_UPLOAD_PROFILE_PICTURE_SUCCESS'
    });
};
var uploadProfilePictureError = function (error) {
    return ({
        type: 'OLY_UPLOAD_PROFILE_PICTURE_ERROR',
        payload: { error: error }
    });
};
var uploadProfilePictureReset = function () {
    return ({
        type: 'OLY_UPLOAD_PROFILE_PICTURE_RESET'
    });
};
export default {
    uploadProfilePicture: uploadProfilePicture,
    uploadProfilePictureSuccess: uploadProfilePictureSuccess,
    uploadProfilePictureError: uploadProfilePictureError,
    uploadProfilePictureReset: uploadProfilePictureReset
};
