var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from '../../utils';
import actions from './actions';
export function uploadUserProfilePicture(api, action) {
    var _a, userId, clientId, formData, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, formData = _a.formData;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .uploadProfilePicture({
                        userId: userId,
                        clientId: clientId,
                        formData: formData
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.uploadProfilePictureSuccess())];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_1 = _b.sent();
                return [4 /*yield*/, put(actions.uploadProfilePictureError(error_1))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function watchUploadUserProfilePicture(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.uploadProfilePicture), uploadUserProfilePicture, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userCoreSagas = function (api) { return [
    fork(watchUploadUserProfilePicture, api),
]; };
export default userCoreSagas;
