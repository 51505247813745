import { OPEN_MODAL, CLOSE_MODAL } from './constants';

const INITIAL_STATE = {
  isOpen: false,
  type: null, // alert, confirm, delete
  entity: null, // challenge, reward, team, ...
  data: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        type: action.payload.type,
        entity: action.payload.entity,
        data: action.payload.data,
        isOpen: true,
      };
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
