import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export const closeModal = () => dispatch => {
  dispatch({ type: CLOSE_MODAL });
};

export const openModal = payload => dispatch => {
  const { type, entity, data } = payload;
  dispatch({ type: OPEN_MODAL, payload: { type, entity, data } });
};
