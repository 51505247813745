import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTransition, animated } from '@react-spring/web';

import { setNotification, unsetNotification } from 'redux/notifications/actions';
import { notificationsDataSelector } from 'redux/notifications/selectors';

import NotificationItem from './NotificationItem';

const Container = styled.div`
  top: 24px;
  position: fixed;
  right: 24px;
  width: 320px;
  z-index: 1000;
`;

const Notifications = props => {
  const { notifications, unsetNotification } = props;

  const notificationsRef = useRef({});

  const transitions = useTransition(notifications, {
    from: { opacity: 0, x: 100, height: 0 },
    enter: item => async next => {
      const node = notificationsRef.current[item.id];

      if (node) {
        const { height } = node.getBoundingClientRect();
        await next({
          opacity: 1,
          x: 0,
          height: height + 10, // +10px marginBottom
        });
      }
    },
    leave: { opacity: 0, x: 100, height: 0 },
  });

  return (
    <Container>
      {transitions((style, item, transitionState, index) => {
        return (
          <animated.div
            key={index}
            style={{
              opacity: style.opacity,
              height: style.height,
              transform: style.x.to(v => `translate3d(${v}%,0,0)`),
            }}
          >
            <div ref={ref => (notificationsRef.current[item.id] = ref)}>
              <NotificationItem
                type={item.type}
                content={item.content}
                title={item.title}
                duration={item.duration}
                autoClose={item.autoClose}
                onClose={() => unsetNotification({ notificationId: item.id })}
              />
            </div>
          </animated.div>
        );
      })}
    </Container>
  );
};

const mapStateToProps = state => ({
  notifications: notificationsDataSelector(state),
});

const mapDispatchToProps = {
  setNotification,
  unsetNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
