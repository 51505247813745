var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var loadUserRegisteredChallenges = function (payload) {
    var _a = payload || {}, clientId = _a.clientId, userId = _a.userId, limit = _a.limit, skip = _a.skip, status = _a.status, search = _a.search;
    return {
        type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES',
        payload: {
            clientId: clientId,
            userId: userId,
            limit: limit,
            skip: skip,
            status: status,
            search: search
        }
    };
};
var loadUserRegisteredChallengesSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES_SUCCESS',
        payload: __assign({}, data)
    });
};
var loadUserRegisteredChallengesError = function (error) {
    return ({
        type: 'OLY_LOAD_USER_REGISTERED_CHALLENGES_ERROR',
        payload: { error: error }
    });
};
var resetUserRegisteredChallenges = function () {
    return ({
        type: 'OLY_RESET_USER_REGISTERED_CHALLENGES'
    });
};
// Load user contribution for groupChallenge
var loadUserGroupChallengeStats = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_REQUEST',
        payload: payload
    });
};
var loadUserGroupChallengeStatsSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_SUCCESS',
        payload: __assign({}, data)
    });
};
var loadUserGroupChallengeStatsError = function (error) {
    return ({
        type: 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_ERROR',
        payload: { error: error }
    });
};
var resetUserGroupChallengeStats = function () {
    return ({
        type: 'OLY_RESET_USER_GROUP_CHALLENGE_STATS'
    });
};
// Register to challenge
var challengeRegister = function (payload) {
    return ({
        type: 'OLY_REGISTER_CHALLENGE_REQUEST',
        payload: payload
    });
};
var challengeRegisterSuccess = function () {
    return ({
        type: 'OLY_REGISTER_CHALLENGE_SUCCESS'
    });
};
var challengeRegisterError = function (error) {
    return ({
        type: 'OLY_REGISTER_CHALLENGE_ERROR',
        payload: error
    });
};
// Load user challenge
var loadUserChallenge = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_REQUEST',
        payload: payload
    });
};
var loadUserChallengeSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_SUCCESS',
        payload: data
    });
};
var loadUserChallengeError = function (error) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ERROR',
        payload: error
    });
};
// Update user challenge
var updateUserChallenge = function (payload) {
    return ({
        type: 'OLY_UPDATE_USER_CHALLENGE_REQUEST',
        payload: payload
    });
};
var updateUserChallengeSuccess = function () {
    return ({
        type: 'OLY_UPDATE_USER_CHALLENGE_SUCCESS'
    });
};
var updateUserChallengeError = function () {
    return ({
        type: 'OLY_UPDATE_USER_CHALLENGE_ERROR'
    });
};
// Get user challenge activities
var loadUserChallengeActivities = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES',
        payload: payload
    });
};
var loadUserChallengeActivitiesSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_SUCCESS',
        payload: data
    });
};
var loadUserChallengeActivitiesError = function () {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_ERROR'
    });
};
// Get user challenge activities around
var loadUserChallengeActivitiesAround = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND',
        payload: payload
    });
};
var loadUserChallengeActivitiesAroundSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_SUCCESS',
        payload: data
    });
};
var loadUserChallengeActivitiesAroundError = function () {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_ERROR'
    });
};
var resetUserChallengeActivities = function () {
    return ({
        type: 'OLY_RESET_USER_CHALLENGE_ACTIVITIES'
    });
};
// Get user challenge attempts
var loadUserChallengeAttempts = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS',
        payload: payload
    });
};
var loadUserChallengeAttemptsSuccess = function (data) {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_SUCCESS',
        payload: data
    });
};
var loadUserChallengeAttemptsError = function () {
    return ({
        type: 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_ERROR'
    });
};
var resetUserChallengeAttempts = function () {
    return ({
        type: 'OLY_RESET_USER_CHALLENGE_ATTEMPTS'
    });
};
// Reset user challenges state
var resetUserChallenges = function () {
    return ({
        type: 'OLY_RESET_USER_CHALLENGES'
    });
};
var loadUserGroupChallengeRequest = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_GROUP_CHALLENGE_REQUEST',
        payload: payload
    });
};
var loadUserGroupChallengeSuccess = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_GROUP_CHALLENGE_SUCCESS',
        payload: payload
    });
};
var resetUserGroupChallenge = function () {
    return ({
        type: 'OLY_RESET_USER_GROUP_CHALLENGE'
    });
};
var setUserGroupChallengeAttempt = function (payload) {
    return ({
        type: 'OLY_SET_USER_GROUP_CHALLENGE_ATTEMPT',
        payload: payload
    });
};
var setUserGroupChallengeSuccess = function (payload) {
    return ({
        type: 'OLY_SET_USER_GROUP_CHALLENGE',
        payload: payload
    });
};
var userGroupChallengeRequestError = function () {
    return ({
        type: 'OLY_USER_GROUP_CHALLENGE_REQUEST_ERROR'
    });
};
export default {
    challengeRegister: challengeRegister,
    challengeRegisterError: challengeRegisterError,
    challengeRegisterSuccess: challengeRegisterSuccess,
    loadUserChallenge: loadUserChallenge,
    loadUserChallengeActivities: loadUserChallengeActivities,
    loadUserChallengeActivitiesAround: loadUserChallengeActivitiesAround,
    loadUserChallengeActivitiesAroundError: loadUserChallengeActivitiesAroundError,
    loadUserChallengeActivitiesAroundSuccess: loadUserChallengeActivitiesAroundSuccess,
    loadUserChallengeActivitiesError: loadUserChallengeActivitiesError,
    loadUserChallengeActivitiesSuccess: loadUserChallengeActivitiesSuccess,
    loadUserChallengeAttempts: loadUserChallengeAttempts,
    loadUserChallengeAttemptsError: loadUserChallengeAttemptsError,
    loadUserChallengeAttemptsSuccess: loadUserChallengeAttemptsSuccess,
    loadUserChallengeError: loadUserChallengeError,
    loadUserChallengeSuccess: loadUserChallengeSuccess,
    loadUserGroupChallengeStats: loadUserGroupChallengeStats,
    loadUserGroupChallengeStatsError: loadUserGroupChallengeStatsError,
    loadUserGroupChallengeStatsSuccess: loadUserGroupChallengeStatsSuccess,
    loadUserRegisteredChallenges: loadUserRegisteredChallenges,
    loadUserRegisteredChallengesError: loadUserRegisteredChallengesError,
    loadUserRegisteredChallengesSuccess: loadUserRegisteredChallengesSuccess,
    loadUserGroupChallengeSuccess: loadUserGroupChallengeSuccess,
    loadUserGroupChallengeRequest: loadUserGroupChallengeRequest,
    resetUserChallengeActivities: resetUserChallengeActivities,
    resetUserChallengeAttempts: resetUserChallengeAttempts,
    resetUserChallenges: resetUserChallenges,
    resetUserGroupChallengeStats: resetUserGroupChallengeStats,
    resetUserRegisteredChallenges: resetUserRegisteredChallenges,
    resetUserGroupChallenge: resetUserGroupChallenge,
    setUserGroupChallengeAttempt: setUserGroupChallengeAttempt,
    setUserGroupChallengeSuccess: setUserGroupChallengeSuccess,
    updateUserChallenge: updateUserChallenge,
    updateUserChallengeError: updateUserChallengeError,
    updateUserChallengeSuccess: updateUserChallengeSuccess,
    userGroupChallengeRequestError: userGroupChallengeRequestError
};
