import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
export var userRewardsStateSelector = createSelector(userStateSelector, function (user) { return user.rewards; });
export var deleteUserRedeemRewardSelector = createSelector(userRewardsStateSelector, function (rewards) { return rewards.deleteUserRedeemReward; });
export var deletedUserIdRedeemRewardSelector = createSelector(deleteUserRedeemRewardSelector, function (deleteUserRedeemReward) { return deleteUserRedeemReward.userIdRedeemDeleted; });
export var deleteUserRedeemRewardErrorSelector = createSelector(deleteUserRedeemRewardSelector, function (deleteUserRedeemReward) { return deleteUserRedeemReward.error; });
export var deleteUserRedeemRewardIsLoadingSelector = createSelector(deleteUserRedeemRewardSelector, function (deleteUserRedeemReward) { return deleteUserRedeemReward.isLoading; });
export default {
    deletedUserIdRedeemRewardSelector: deletedUserIdRedeemRewardSelector,
    deleteUserRedeemRewardErrorSelector: deleteUserRedeemRewardErrorSelector,
    deleteUserRedeemRewardIsLoadingSelector: deleteUserRedeemRewardIsLoadingSelector
};
