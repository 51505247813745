export var loadUserFeed = function (payload) {
    var _a = payload || {}, userId = _a.userId, clientId = _a.clientId, next = _a.next, apiVersion = _a.apiVersion;
    return {
        type: 'OLY_ENGINE_STORE_LOAD_USER_FEED',
        payload: { userId: userId, clientId: clientId, next: next, apiVersion: apiVersion }
    };
};
export var loadUserFeedSuccess = function (userFeed) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_FEED_SUCCESS',
        payload: { userFeed: userFeed }
    });
};
export var loadUserFeedError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_FEED_ERRROR',
        payload: { error: error }
    });
};
export var resetUserFeed = function () {
    return ({
        type: 'OLY_ENGINE_STORE_RESET_USER_FEED'
    });
};
export default {
    loadUserFeed: loadUserFeed,
    loadUserFeedSuccess: loadUserFeedSuccess,
    loadUserFeedError: loadUserFeedError,
    resetUserFeed: resetUserFeed
};
