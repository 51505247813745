var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fork, put, takeLatest, select, } from 'redux-saga/effects';
import { challengeActions } from '@sportheroes/oly-engine-store';
import { getType } from '../../utils';
import { userRegisteredChallengesDataSelector } from './selectors';
import actions from './actions';
export function loadUserRegisteredChallenges(apis, action) {
    var _a, clientId, userId, limit, skip, status_1, search, fullResponse, UserRegisteredChallengesData, prevData, nextData, newData, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                _a = action.payload, clientId = _a.clientId, userId = _a.userId, limit = _a.limit, skip = _a.skip, status_1 = _a.status, search = _a.search;
                return [4 /*yield*/, apis.userApi
                        .getUserRegisteredChallengesNew({
                        clientId: clientId,
                        userId: userId,
                        limit: limit,
                        skip: skip,
                        status: status_1,
                        search: search
                    })
                        .then(function (p) { return p.request; })];
            case 1:
                fullResponse = _b.sent();
                return [4 /*yield*/, select(userRegisteredChallengesDataSelector)];
            case 2:
                UserRegisteredChallengesData = _b.sent();
                prevData = UserRegisteredChallengesData || [];
                nextData = fullResponse.data || [];
                newData = __spreadArray(__spreadArray([], prevData, true), nextData, true);
                return [4 /*yield*/, put(actions.loadUserRegisteredChallengesSuccess(__assign(__assign({}, fullResponse), { data: newData, meta: fullResponse.meta })))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_1 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserRegisteredChallengesError(true))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function loadUserGroupChallengeStats(apis, action) {
    var _a, challengeId, clientId, userId, data, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, challengeId = _a.challengeId, clientId = _a.clientId, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.userApi
                        .getUserChallengeStats({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserGroupChallengeStatsSuccess({ data: data }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_2 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserGroupChallengeStatsError(true))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function challengeRegister(apis, action) {
    var _a, userId, clientId, challengeId, code, restart, groupId, language, defaultLanguage, payload, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, challengeId = _a.challengeId, code = _a.code, restart = _a.restart, groupId = _a.groupId, language = _a.language, defaultLanguage = _a.defaultLanguage;
                payload = {
                    challenge: challengeId,
                    restart: restart
                };
                if (groupId) {
                    payload.group = groupId;
                }
                if (code) {
                    payload.code = code;
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 9, , 11]);
                if (!restart) return [3 /*break*/, 3];
                return [4 /*yield*/, apis.userApi
                        .restartChallenge({
                        userId: userId,
                        clientId: clientId,
                        challengeId: challengeId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, apis.userApi
                    .registerChallenge({
                    userId: userId,
                    clientId: clientId,
                    payload: payload
                })
                    .then(function (p) { return p.request; })];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [4 /*yield*/, put(actions.challengeRegisterSuccess())];
            case 6:
                _b.sent();
                // ``isCurrentUserRegister`` utils stills needs userChallenge in challenge updated after a registration.
                // To migrate next.
                return [4 /*yield*/, put(challengeActions.loadChallenge({
                        challengeId: challengeId,
                        clientId: clientId,
                        language: language,
                        defaultLanguage: defaultLanguage
                    }))];
            case 7:
                // ``isCurrentUserRegister`` utils stills needs userChallenge in challenge updated after a registration.
                // To migrate next.
                _b.sent();
                // Load userChallenge after a registration
                return [4 /*yield*/, put(actions.loadUserChallenge({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    }))];
            case 8:
                // Load userChallenge after a registration
                _b.sent();
                return [3 /*break*/, 11];
            case 9:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.challengeRegisterError(error_3))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export function loadUserChallenge(apis, action) {
    var _a, challengeId, clientId, userId, data, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload || {}, challengeId = _a.challengeId, clientId = _a.clientId, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.userApi
                        .getUserChallenge({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeSuccess(data))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_4 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeError(error_4))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function updateUserChallenge(apis, action) {
    var _a, challengeId, clientId, data, userId, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, challengeId = _a.challengeId, clientId = _a.clientId, data = _a.data, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, apis.userApi
                        .updateUserChallenge({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId,
                        data: data
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.updateUserChallengeSuccess())];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallenge({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                error_5 = _b.sent();
                return [4 /*yield*/, put(actions.updateUserChallengeError())];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function getUserChallengeActivities(apis, action) {
    var _a, challengeId, clientId, userId, data, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, challengeId = _a.challengeId, clientId = _a.clientId, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.userApi
                        .getUserChallengeActivities({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeActivitiesSuccess({ data: data }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_6 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeActivitiesError())];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getUserChallengeActivitiesAround(apis, action) {
    var _a, challengeId, clientId, userId, data, error_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, challengeId = _a.challengeId, clientId = _a.clientId, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.userApi
                        .getUserChallengeActivitiesAround({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeActivitiesAroundSuccess({ data: data }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_7 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeActivitiesAroundError())];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getUserChallengeAttempts(apis, action) {
    var _a, challengeId, clientId, userId, data, error_8;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, challengeId = _a.challengeId, clientId = _a.clientId, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.userApi
                        .getUserChallengeAttempts({
                        challengeId: challengeId,
                        clientId: clientId,
                        userId: userId
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeAttemptsSuccess({ data: data }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_8 = _b.sent();
                return [4 /*yield*/, put(actions.loadUserChallengeAttemptsError())];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function getUserGroupChallenge(apis, action) {
    var _a, resourceType, resourceId, id, withMembers, data, error_9;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload || {}, resourceType = _a.resourceType, resourceId = _a.resourceId, id = _a.id, withMembers = _a.withMembers;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, apis.groupsApi
                        .getOneResourceGroup({
                        resourceType: resourceType,
                        resourceId: resourceId,
                        id: id,
                        withMembers: withMembers
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadUserGroupChallengeSuccess(data))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_9 = _b.sent();
                return [4 /*yield*/, put(actions.userGroupChallengeRequestError())];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function setUserGroupChallenge(action) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(actions.setUserGroupChallengeSuccess(action.payload))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchUserRegisteredChallenges(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserRegisteredChallenges), loadUserRegisteredChallenges, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchLoadUserGroupChallengeStats(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserGroupChallengeStats), loadUserGroupChallengeStats, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchRegisterChallenge(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.challengeRegister), challengeRegister, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchLoadUserChallenge(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserChallenge), loadUserChallenge, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchUpdateUserChallenge(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.updateUserChallenge), updateUserChallenge, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchGetUserChallengeActivities(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserChallengeActivities), getUserChallengeActivities, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchGetUserChallengeActivitiesAround(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserChallengeActivitiesAround), getUserChallengeActivitiesAround, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchGetUserChallengeAttempts(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserChallengeAttempts), getUserChallengeAttempts, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchLoadUserGroupChallenge(apis) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserGroupChallengeRequest), getUserGroupChallenge, apis)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchSetUserGroupChallenge() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.setUserGroupChallengeAttempt), setUserGroupChallenge)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userChallengesSagas = function (apis) { return [
    fork(watchGetUserChallengeActivities, apis),
    fork(watchGetUserChallengeActivitiesAround, apis),
    fork(watchGetUserChallengeAttempts, apis),
    fork(watchLoadUserChallenge, apis),
    fork(watchLoadUserGroupChallengeStats, apis),
    fork(watchRegisterChallenge, apis),
    fork(watchUpdateUserChallenge, apis),
    fork(watchUserRegisteredChallenges, apis),
    fork(watchLoadUserGroupChallenge, apis),
    fork(watchSetUserGroupChallenge),
]; };
export default userChallengesSagas;
