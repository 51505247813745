import React from 'react';

const Alert = () => (
  <svg width={4} height={10}>
    <path
      d="M1.25.75a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0V.75zm.787 8.633h-.02A1.146 1.146 0 0 1 .875 8.28a1.107 1.107 0 0 1 1.086-1.147h.021c.614 0 1.119.486 1.143 1.1a1.106 1.106 0 0 1-1.088 1.15z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default Alert;
