import {
  FETCH_USERS_SEARCH_PENDING,
  FETCH_USERS_SEARCH_SUCCESS,
  FETCH_USERS_SEARCH_ERROR,
  RESET_USERS_SEARCH,
} from './constants';

const INITIAL_STATE = {
  isLoading: false,
  error: null,

  hasMore: false,
  data: null,
  skip: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS_SEARCH_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USERS_SEARCH_SUCCESS: {
      const { payload: { data: payloadData = [], hasMore = false, skip = 0 } = {} } = action || {};

      const data = skip ? [...(state.data || []), ...payloadData] : payloadData;

      return {
        ...state,
        data,
        skip,
        hasMore,
        error: null,
        isLoading: false,
      };
    }
    case FETCH_USERS_SEARCH_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case RESET_USERS_SEARCH:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
