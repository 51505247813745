import shortid from 'shortid';
import Cookies from 'js-cookie';
import { userUtils } from '@sportheroes/oly-engine-utils';
import storage from 'utils/storage';
import { olyEngineApi } from './olyEngineApi';
export var getDeviceId = function () {
    var deviceId = storage.get('deviceId');
    if (!deviceId) {
        var newDeviceId = shortid.generate();
        storage.set('deviceId', newDeviceId);
        return newDeviceId;
    }
    return deviceId;
};
export var getDeviceName = function () {
    return userUtils === null || userUtils === void 0 ? void 0 : userUtils.getDeviceName(navigator === null || navigator === void 0 ? void 0 : navigator.userAgent);
};
export var getCredentials = function () {
    return {
        refreshToken: Cookies.get('zeus-admin-refreshToken') || '',
        accessToken: Cookies.get('zeus-admin-accessToken') || '',
        _id: storage.get('userId') || ''
    };
};
export var storeAccessToken = function (accessToken) {
    Cookies.set('zeus-admin-accessToken', accessToken, { expires: 730 });
};
export var storeCredentials = function (_a) {
    var _b = _a.refreshToken, refreshToken = _b === void 0 ? '' : _b, _c = _a.accessToken, accessToken = _c === void 0 ? '' : _c, _d = _a._id, _id = _d === void 0 ? '' : _d;
    Cookies.set('zeus-admin-refreshToken', refreshToken, { expires: 730 });
    Cookies.set('zeus-admin-accessToken', accessToken, { expires: 730 });
    storage.set('userId', _id);
};
export var removeCredentials = function () {
    Cookies.remove('zeus-admin-refreshToken');
    Cookies.remove('zeus-admin-accessToken');
    storage.remove('userId');
};
export var resetApiTokens = function () {
    olyEngineApi.accessToken = undefined;
    olyEngineApi.refreshToken = undefined;
};
export var setApiTokens = function (_a) {
    var accessToken = _a.accessToken, refreshToken = _a.refreshToken;
    olyEngineApi.accessToken = accessToken;
    olyEngineApi.refreshToken = refreshToken;
};
