var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var initialState = {
    userRegisteredChallenges: {
        meta: null,
        data: null,
        error: false
    },
    userGroupChallengeContribution: {
        data: null,
        isLoading: null,
        error: null
    },
    registration: {
        success: null,
        error: null,
        isLoading: null
    },
    userChallengeActivities: {
        data: null,
        meta: null,
        error: null,
        isLoading: null
    },
    userChallengeActivitiesAround: {
        data: null,
        meta: null,
        error: null,
        isLoading: null
    },
    userChallengeAttempts: {
        data: null,
        meta: null,
        error: null,
        isLoading: null
    },
    hasUpdatedUserChallenge: null,
    userChallenge: { data: null },
    userGroupChallenge: {
        data: null,
        isLoading: null
    }
};
function UserChallengesReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'OLY_LOAD_USER_REGISTERED_CHALLENGES_SUCCESS':
            return __assign(__assign({}, state), { userRegisteredChallenges: __assign(__assign({}, state.userRegisteredChallenges), { data: action.payload.data, meta: action.payload.meta }) });
        case 'OLY_LOAD_USER_REGISTERED_CHALLENGES_ERROR':
            return __assign(__assign({}, state), { userRegisteredChallenges: __assign(__assign({}, state.userRegisteredChallenges), { error: action.payload.error }) });
        case 'OLY_RESET_USER_REGISTERED_CHALLENGES':
            return __assign(__assign({}, state), { userRegisteredChallenges: {
                    meta: null,
                    data: null,
                    error: null
                } });
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_REQUEST':
            return __assign(__assign({}, state), { userGroupChallengeContribution: __assign(__assign({}, state.userGroupChallengeContribution), { isLoading: true }) });
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_SUCCESS':
            return __assign(__assign({}, state), { userGroupChallengeContribution: __assign(__assign({}, state.userGroupChallengeContribution), { data: action.payload.data, isLoading: false }) });
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_STATS_ERROR':
            return __assign(__assign({}, state), { userGroupChallengeContribution: __assign(__assign({}, state.userGroupChallengeContribution), { isLoading: false, error: true }) });
        case 'OLY_RESET_USER_GROUP_CHALLENGE_STATS':
            return initialState;
        case 'OLY_REGISTER_CHALLENGE_REQUEST':
            return __assign(__assign({}, state), { registration: __assign(__assign({}, initialState.registration), { isLoading: true }) });
        case 'OLY_REGISTER_CHALLENGE_SUCCESS':
            return __assign(__assign({}, state), { registration: __assign(__assign({}, state.registration), { success: true, isLoading: false }) });
        case 'OLY_REGISTER_CHALLENGE_ERROR':
            return __assign(__assign({}, state), { registration: __assign(__assign({}, state.registration), { error: action.payload.userMsg ? action.payload.userMsg : true, isLoading: false }) });
        case 'OLY_LOAD_USER_CHALLENGE_SUCCESS':
            return __assign(__assign({}, state), { userChallenge: { data: action.payload } });
        case 'OLY_UPDATE_USER_CHALLENGE_SUCCESS':
            return __assign(__assign({}, state), { hasUpdatedUserChallenge: true });
        case 'OLY_UPDATE_USER_CHALLENGE_ERROR':
            return __assign(__assign({}, state), { hasUpdatedUserChallenge: false });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES':
            return __assign(__assign({}, state), { userChallengeActivities: __assign(__assign({}, state.userChallengeActivities), { isLoading: true }) });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_SUCCESS':
            return __assign(__assign({}, state), { userChallengeActivities: __assign(__assign(__assign({}, state.userChallengeActivities), action.payload.data), { isLoading: false }) });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_ERROR':
            return __assign(__assign({}, state), { userChallengeActivities: __assign(__assign({}, state.userChallengeActivities), { error: true, isLoading: false }) });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND':
            return __assign(__assign({}, state), { userChallengeActivitiesAround: __assign(__assign({}, state.userChallengeActivitiesAround), { isLoading: true }) });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_SUCCESS':
            return __assign(__assign({}, state), { userChallengeActivitiesAround: __assign(__assign(__assign({}, state.userChallengeActivitiesAround), action.payload.data), { isLoading: false }) });
        case 'OLY_LOAD_USER_CHALLENGE_ACTIVITIES_AROUND_ERROR':
            return __assign(__assign({}, state), { userChallengeActivitiesAround: __assign(__assign({}, state.userChallengeActivitiesAround), { error: true, isLoading: false }) });
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_SUCCESS':
            return __assign(__assign({}, state), { userGroupChallenge: {
                    data: action.payload,
                    isLoading: false
                } });
        case 'OLY_LOAD_USER_GROUP_CHALLENGE_REQUEST':
            return __assign(__assign({}, state), { userGroupChallenge: __assign(__assign({}, state.userGroupChallenge), { isLoading: true }) });
        case 'OLY_SET_USER_GROUP_CHALLENGE':
            return __assign(__assign({}, state), { userGroupChallenge: __assign(__assign({}, state.userGroupChallenge), { data: action.payload }) });
        case 'OLY_RESET_USER_GROUP_CHALLENGE':
            return __assign(__assign({}, state), { userGroupChallenge: __assign({}, initialState.userGroupChallenge) });
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS':
            return __assign(__assign({}, state), { userChallengeAttempts: __assign(__assign({}, state.userChallengeAttempts), { isLoading: true }) });
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_SUCCESS':
            return __assign(__assign({}, state), { userChallengeAttempts: __assign(__assign(__assign({}, state.userChallengeAttempts), action.payload.data), { isLoading: false }) });
        case 'OLY_LOAD_USER_CHALLENGE_ATTEMPTS_ERROR':
            return __assign(__assign({}, state), { userChallengeAttempts: __assign(__assign({}, state.userChallengeAttempts), { error: true, isLoading: false }) });
        case 'OLY_RESET_USER_CHALLENGE_ATTEMPTS':
            return __assign(__assign({}, state), { userChallengeAttempts: __assign({}, initialState.userChallengeAttempts) });
        case 'OLY_RESET_USER_CHALLENGE_ACTIVITIES':
            return __assign(__assign({}, state), { userChallengeActivitiesAround: __assign({}, initialState.userChallengeActivitiesAround), userChallengeActivities: __assign({}, initialState.userChallengeActivities) });
        case 'OLY_RESET_USER_CHALLENGES':
            return initialState;
        default:
            return state;
    }
}
export default UserChallengesReducer;
