import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
var userAppsStateSelector = createSelector(userStateSelector, function (user) { return user.apps; });
var loadUserAppsSelector = createSelector(userAppsStateSelector, function (userApps) { return userApps.loadUserApps; });
var loadUserAppsDataSelector = createSelector(loadUserAppsSelector, function (loadUserApps) { return loadUserApps.data; });
var loadUserAppsIsLoadingSelector = createSelector(loadUserAppsSelector, function (loadUserApps) { return loadUserApps.isLoading; });
var loadUserAppsErrorSelector = createSelector(loadUserAppsSelector, function (loadUserApps) { return loadUserApps.error; });
var pullUserAppsSelector = createSelector(userAppsStateSelector, function (userApps) { return userApps.pullUserApps; });
var pullUserAppsSuccessSelector = createSelector(pullUserAppsSelector, function (pullUserApps) { return pullUserApps.success; });
var pullUserAppsIsLoadingSelector = createSelector(pullUserAppsSelector, function (pullUserApps) { return pullUserApps.isLoading; });
var pullUserAppsErrorSelector = createSelector(pullUserAppsSelector, function (pullUserApps) { return pullUserApps.error; });
var deleteUserAppSelector = createSelector(userAppsStateSelector, function (userApps) { return userApps.deleteUserApp; });
var deletedUserAppNameSelector = createSelector(deleteUserAppSelector, function (deleteUserApp) { return deleteUserApp.deletedAppName; });
var deleteUserAppIsLoadingSelector = createSelector(deleteUserAppSelector, function (deleteUserApp) { return deleteUserApp.isLoading; });
var deleteUserAppErrorSelector = createSelector(deleteUserAppSelector, function (deleteUserApp) { return deleteUserApp.error; });
export default {
    loadUserAppsDataSelector: loadUserAppsDataSelector,
    loadUserAppsIsLoadingSelector: loadUserAppsIsLoadingSelector,
    loadUserAppsErrorSelector: loadUserAppsErrorSelector,
    pullUserAppsSuccessSelector: pullUserAppsSuccessSelector,
    pullUserAppsIsLoadingSelector: pullUserAppsIsLoadingSelector,
    pullUserAppsErrorSelector: pullUserAppsErrorSelector,
    deletedUserAppNameSelector: deletedUserAppNameSelector,
    deleteUserAppIsLoadingSelector: deleteUserAppIsLoadingSelector,
    deleteUserAppErrorSelector: deleteUserAppErrorSelector
};
