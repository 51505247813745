import ClientsService from 'services/Clients';
import { resetChallenges } from 'redux/challenges/actions';
import { resetUsers } from 'redux/users/actions';
import { uploadImageFormated } from 'redux/images/actions';
import { challengesDataSelector } from 'redux/challenges/selectors';
import { usersDataSelector } from 'redux/users/selectors';

import {
  FETCH_CLIENTS_PENDING,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
  FETCH_CURRENT_CLIENT_SUCCESS,
  FETCH_CURRENT_CLIENT_ERROR,
  RESET_CLIENTS,
  RESET_CURRENT_CLIENT,
} from './constants';

export const fetchClients = () => async dispatch => {
  dispatch({ type: FETCH_CLIENTS_PENDING });
  try {
    const { results: clients } = await ClientsService.getClient().then(p => p.request);
    dispatch({ type: FETCH_CLIENTS_SUCCESS, payload: { clients } });
  } catch (error) {
    dispatch({ type: FETCH_CLIENTS_ERROR, payload: { error } });
  }
};
export const resetClients = () => ({
  type: RESET_CLIENTS,
});

export const fetchCurrentClient = clientId => async (dispatch, getState) => {
  try {
    if (!clientId) {
      throw new Error({ message: 'clientId is missing' });
    }
    const currentClient = await ClientsService.getClient({ id: clientId }).then(p => p.request);

    dispatch({ type: FETCH_CURRENT_CLIENT_SUCCESS, payload: { currentClient } });

    const challengesData = challengesDataSelector(getState());
    const usersData = usersDataSelector(getState());

    const promises = [];

    if (challengesData) {
      promises.push(dispatch(resetChallenges()));
    }
    if (usersData) {
      promises.push(dispatch(resetUsers()));
    }

    return Promise.all(promises);
  } catch (error) {
    dispatch({ type: FETCH_CURRENT_CLIENT_ERROR, payload: { error } });
  }
};

export const resetCurrentClient = () => dispatch => {
  dispatch({ type: RESET_CURRENT_CLIENT });
};

export const uploadImageClient = ({ file, clientId, mandatoryFileName }) => {
  return uploadImageFormated({ file, id: clientId, path: 'clients', mandatoryFileName });
};
