import * as Sentry from '@sentry/browser';

export const STORAGE_ROOT_KEY = '@zeus-admin';
export const TINY_MCE_PREFIX_KEY = 'tinymce-autosave-';

export default {
  get: key => {
    const value = localStorage.getItem(`${STORAGE_ROOT_KEY}:${key}`);

    if (value === null) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  set: (key, value) => {
    value = typeof value === 'object' ? JSON.stringify(value) : value;
    try {
      localStorage.setItem(`${STORAGE_ROOT_KEY}:${key}`, value);
    } catch (error) {
      Sentry.captureException(error);
    }
  },
  remove: key => localStorage.removeItem(`${STORAGE_ROOT_KEY}:${key}`),
  removeKeysFromTinyMCE: () => {
    Object.keys(localStorage)
      .filter(key => key.startsWith(TINY_MCE_PREFIX_KEY))
      .forEach(key => localStorage.removeItem(key));
  },
};
