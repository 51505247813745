import { FETCH_GROUPS_PENDING, FETCH_GROUPS_SUCCESS, FETCH_GROUPS_ERROR } from './constants';

const INITIAL_STATE = {
  data: null,
  hasMore: true,
  isLoading: false,
  error: null,
  filters: null,
  sorting: null,
  skip: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GROUPS_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_GROUPS_SUCCESS: {
      const { payload: { data: payloadData = [], hasMore = false, skip = 0 } = {} } = action || {};

      const data = skip ? [...(state.data || []), ...payloadData] : payloadData;

      return {
        ...state,
        data,
        skip,
        hasMore,
        error: null,
        isLoading: false,
      };
    }

    case FETCH_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        data: null,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
