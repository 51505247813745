import React, { useState, useCallback, createContext } from 'react';

const ModalContext = createContext();

export const ModalProvider = (props) => {
  const { children, isLoading = false } = props;
  const [isLoadingState, setIsLoadingState] = useState(isLoading);

  const handleDetIsLoadingState = useCallback((value) => {
    setIsLoadingState(value);
  }, []);

  return (
    <ModalContext.Provider
      value={{ isLoading: isLoadingState, setIsLoading: handleDetIsLoadingState }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
