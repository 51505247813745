import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
export var userClientStateSelector = createSelector(userStateSelector, function (user) { return user.clients; });
export var userClientsSelector = createSelector(userClientStateSelector, function (clients) { return clients.userClients; });
export var currentAdminUserClientsSelector = createSelector(userClientStateSelector, function (clients) { return clients.currentAdminUserClients; });
export var currentUserClientIdSelector = createSelector(userClientStateSelector, function (clients) { return clients.currentUserClientId; });
export var currentUserClientSelector = createSelector(userClientStateSelector, function (clients) { var _a; return (_a = clients.userClients) === null || _a === void 0 ? void 0 : _a.find(function (userClient) { var _a; return ((_a = userClient.client) === null || _a === void 0 ? void 0 : _a._id) === clients.currentUserClientId; }); });
export var getCurrentUserClientSelector = createSelector(userClientStateSelector, function (clients) { return clients.currentUserClient; });
export var getCurrentUserClientSettingsSelector = createSelector(getCurrentUserClientSelector, function (userClient) { return userClient === null || userClient === void 0 ? void 0 : userClient.settings; });
export var userClientsErrorSelector = createSelector(userClientStateSelector, function (clients) { return clients.error; });
export default {
    userClientsSelector: userClientsSelector,
    currentAdminUserClientsSelector: currentAdminUserClientsSelector,
    currentUserClientIdSelector: currentUserClientIdSelector,
    currentUserClientSelector: currentUserClientSelector,
    userClientsErrorSelector: userClientsErrorSelector,
    getCurrentUserClientSelector: getCurrentUserClientSelector,
    getCurrentUserClientSettingsSelector: getCurrentUserClientSettingsSelector
};
