import React from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import WarningRoundIcon from '@sportheroes/oly-ds/icons/WarningRoundIcon';

import Button from 'components/ButtonOly';
import Flash from 'icons/Flash';

import {
  Container,
  Content,
  LogoContainer,
  MainTextContainer,
  WarningIconContainer,
  MainText,
  SecondaryText,
  ButtonContainer,
  ButtonText,
} from './styled';
import { ERRORS_BLACKLIST } from './constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      if (errorInfo) {
        scope.setExtra('componentStack', errorInfo);
      }

      Sentry.captureException(error);
    });

    if (ERRORS_BLACKLIST.some(el => error?.toString().includes(el))) {
      window.location.reload();
    }
  }

  render() {
    const { children, t } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container>
          <Content>
            <LogoContainer>
              <Flash fill="gold" />
            </LogoContainer>
            <MainTextContainer>
              <WarningIconContainer>
                <WarningRoundIcon />
              </WarningIconContainer>
              <MainText>{t('ds-errorboundary-title')}</MainText>
            </MainTextContainer>
            <SecondaryText>{t('ds-errorboundary-subtitle')}</SecondaryText>
            <ButtonContainer>
              <Button
                appearance="danger"
                onClick={() => window.location.reload()}
                title={<ButtonText>{t('ds-errorboundary-button')}</ButtonText>}
                borderRadius="100px"
              />
            </ButtonContainer>
          </Content>
        </Container>
      );
    }

    return children;
  }
}
export default compose(withTranslation())(ErrorBoundary);
