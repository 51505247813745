export const FETCH_CURRENT_CLIENT_SUCCESS = 'FETCH_CURRENT_CLIENT_SUCCESS';
export const FETCH_CURRENT_CLIENT_ERROR = 'FETCH_CURRENT_CLIENT_ERROR';

export const FETCH_CLIENTS_PENDING = 'FETCH_CLIENTS_PENDING';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR';

export const RESET_CLIENTS = 'RESET_CLIENTS';

export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
export const RESET_CURRENT_CLIENT = 'RESET_CURRENT_CLIENT';
