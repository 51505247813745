import Cookies from 'js-cookie';
import * as Sentry from '@sentry/browser';

import {
  storeCredentials,
  removeCredentials,
  setApiTokens,
  resetApiTokens,
} from 'services/Config/utils';

import UsersService from '../../services/Users';
import { resetChallenges } from '../challenges/actions';
import { resetUsers } from '../users/actions';
import { resetClients } from '../clients/actions';
import storage from '../../utils/storage';

import { LOGIN, LOGOUT, SET_AVAILABLE_CLIENTS, SET_USER_ROLES } from './constants';

const { CLIENT_ID } = process.env;

export const logout = () => async dispatch => {
  // reset all reducers when login out
  const promises = [
    dispatch({ type: LOGOUT }),
    dispatch(resetClients()),
    dispatch(resetChallenges()),
    dispatch(resetUsers()),
  ];

  await Promise.all(promises);

  removeCredentials();
  resetApiTokens();
  window.open('/login', '_self');
};

export const fetchUser = payload => async dispatch => {
  const { userId, shouldLogin = true } = payload;

  const user = await UsersService.getUser({
    id: userId,
    clientId: CLIENT_ID,
  }).then(p => p.request);

  Sentry.setUser({
    id: userId,
    username: user.name,
    email: user.email,
  });

  const roles = await UsersService.getUserRoles({
    userId,
  }).then(p => p.request);

  if (!roles?.length) {
    dispatch(logout());
  } else {
    dispatch({ type: SET_USER_ROLES, payload: { roles } });

    if (shouldLogin) {
      dispatch({ type: LOGIN, payload: { user } });
    }
  }
};

export const fetchAvailableClients = payload => async dispatch => {
  const { email } = payload;

  const availableClients = await UsersService.getUserAvailableClients({
    email,
  }).then(p => p.request);

  dispatch({ type: SET_AVAILABLE_CLIENTS, payload: { availableClients } });
};

export const getCredentialsFromTokenState = async tokenState => {
  try {
    const { _id, accessToken, refreshToken } = await UsersService.getTokenState({
      tokenState,
    }).then(p => p.request);

    return { _id, accessToken, refreshToken };
  } catch {
    return null;
  }
};

export const getCredentialsFromOldToken = async ({ oldToken, clientId }) => {
  try {
    const res = await UsersService.getTokenStateFromOldToken({
      oldToken,
      clientId,
    }).then(p => p.request);
    const { _id, accessToken, refreshToken } = res;
    return { _id, accessToken, refreshToken };
  } catch {
    return null;
  }
};

export const login = payload => async () => {
  const { userId, token, tokenState, clientId } = payload;

  if (userId) {
    try {
      let credentials;
      if (token) {
        credentials = await getCredentialsFromOldToken({ oldToken: token, clientId });
      }

      if (tokenState) {
        credentials = await getCredentialsFromTokenState(tokenState);
      }

      if (credentials) {
        storeCredentials({ ...credentials, _id: userId });
        window.open('/', '_self');
      }
    } catch {
      return {
        email: 'Email &/or password invalid.',
        password: 'Email &/or password invalid.',
      };
    }
  }
};

export const logoutOnFetchUserFail = (dispatch, error) => {
  const errorCode = Number.isInteger(error?.status) ? error?.status : error?.code;
  if (errorCode < 500) {
    dispatch(logout());
  }
};

export const connectUser = () => async dispatch => {
  const { CLIENT_ID } = process.env;

  const userId = storage.get('userId');
  const token = storage.get('token');
  const accessToken = Cookies.get('zeus-admin-accessToken');
  const refreshToken = Cookies.get('zeus-admin-refreshToken');

  if (refreshToken && accessToken && userId) {
    return dispatch(fetchUser({ userId })).catch(error => {
      logoutOnFetchUserFail(dispatch, error);
    });
  }

  if (token && userId) {
    const credentials = await getCredentialsFromOldToken({ oldToken: token, clientId: CLIENT_ID });

    const { accessToken, refreshToken } = credentials || {};

    if (accessToken && refreshToken) {
      setApiTokens(credentials);
      storeCredentials(credentials);
      storage.remove('token');

      return dispatch(fetchUser({ userId })).catch(error => {
        logoutOnFetchUserFail(dispatch, error);
      });
    }
    return dispatch(logout());
  }
};
