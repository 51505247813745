var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import Pressable from '@sportheroes/oly-ds/components/Pressable';
import { getButtonColors } from './utils';
import { Container } from './styled';
var ButtonOly = function (props) {
    var _a = props.appearance, appearance = _a === void 0 ? 'primary' : _a, _b = props.borderRadius, borderRadius = _b === void 0 ? '4px' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? false : _c, _d = props.isDisabled, isDisabled = _d === void 0 ? false : _d, _e = props.isLoading, isLoading = _e === void 0 ? false : _e, _f = props.size, size = _f === void 0 ? 'small' : _f, title = props.title, href = props.href, icon = props.icon, onClick = props.onClick, marginRight = props.marginRight, justifyContent = props.justifyContent, testId = props.testId, color = props.color;
    var buttonColors = useMemo(function () {
        return getButtonColors(appearance);
    }, [appearance]);
    var customColor = buttonColors.customColor, customHoverColor = buttonColors.customHoverColor, customTextColor = buttonColors.customTextColor;
    return (_jsx(Container, __assign({ marginRight: marginRight, isDisabled: isDisabled, justifyContent: justifyContent, "data-testid": testId }, { children: _jsx(Pressable, { customColor: color || customColor, customHoverColor: color || customHoverColor, customTextColor: customTextColor, fullWidth: fullWidth, href: href, icon: icon, isDisabled: isDisabled, loading: isLoading, onPress: onClick, size: size, title: title, borderRadius: borderRadius, wrapperStyle: { width: fullWidth ? '100%' : 'auto' } }) })));
};
export default ButtonOly;
