import get from 'lodash/get';
import moment from 'moment';

import { getChallengeType } from 'utils/challenge';
import * as CONSTANTS from 'constants/challenge';

import { formattedFunctions } from './helpers';
import challengeSchema from './schema/challenge';

const getPropertyType = ({ data, defaultLanguage, key, defaultValue = null }) => {
  return get(data[defaultLanguage], key) || data[key] || defaultValue;
};

export const challengeToForm = (languages, challenge, defaultLanguage, duplicating = false) => {
  const countryValues = formattedFunctions.countryValues(languages);
  const basicChallenge = challengeSchema({ countryValues });

  if (!challenge) {
    return basicChallenge;
  }

  const today = moment().toISOString();
  const challengeDisplay = get(challenge, CONSTANTS.DISPLAY, {});
  const challengeBonusConfig = get(challenge, CONSTANTS.BONUS_CONFIG, {});
  const challengeConditions = get(challenge, CONSTANTS.CONDITIONS, {});
  const challengeTimeframe = get(challenge, CONSTANTS.TIMEFRAME, {});
  const challengeStart = get(challengeTimeframe, CONSTANTS.START);
  const challengeLeaderboard = get(challenge, CONSTANTS.LEADERBORD, {});
  const challengeEnd = get(challengeTimeframe, CONSTANTS.END);
  const challengeOnlineDates = get(challenge, CONSTANTS.ONLINE);
  const challengeOnlineStart = get(challengeOnlineDates, CONSTANTS.START);
  const challengeOnlineEnd = get(challengeOnlineDates, CONSTANTS.END);
  const challengeRegistration = get(challenge, CONSTANTS.REGISTRATION, {});
  const challengeRegistrationConditions = get(challengeRegistration, CONSTANTS.CONDITIONS, {});
  const challengeType =
    get(challenge, CONSTANTS.CHALLENGE_TYPE) ||
    getChallengeType(challenge.registration, challenge.tags);
  const challengeCrm = get(challenge, CONSTANTS.CRM, {});

  const formattedDisplayForform = formattedFunctions.getChallengeDisplay({
    types: [
      CONSTANTS.TITLE,
      CONSTANTS.SLUG,
      CONSTANTS.TYPE,
      CONSTANTS.CONTENT,
      CONSTANTS.EXCERPT,
      CONSTANTS.REWARD_NAME,
      CONSTANTS.REWARD_CONDITIONS,
      CONSTANTS.OBJECTIVE,
      CONSTANTS.HIGHLIGHT_CTA,
      CONSTANTS.PROMOTE_TEXT,
    ],
    display: challengeDisplay,
    languages,
    defaultLanguage,
  });

  const formattedProducts = formattedFunctions.getChallengeProducts({
    products: get(challenge, CONSTANTS.PRODUCTS, []),
    languages,
    defaultLanguage,
    duplicating,
  });

  const parsed = {
    ...basicChallenge,
    ...{
      activities: {
        ...challenge.activities,
      },
      general: {
        _id: !duplicating ? challenge._id : undefined,
        reference: {
          ...basicChallenge.general.reference,
          ...(!duplicating && { value: challenge.name, isDisabled: !!challenge.name }),
        },
        slug: {
          ...basicChallenge.general.slug,
          ...(!duplicating && {
            value: challenge.slug || null,
            isDisabled: !!challenge.slug,
          }),
        },
        type: {
          value: challengeType,
          isDisabled: !!challengeType && !duplicating,
        },
        start: {
          value: challengeStart,
        },
        end: {
          value: challengeEnd,
        },
        onlineStartDate: {
          value: challengeOnlineStart,
        },
        onlineEndDate: {
          value: challengeOnlineEnd,
        },
        leaderboard: {
          isApplicableToMsRanking: challengeLeaderboard.isApplicableToMsRanking || false,
          isCrossGroupChallenge: challengeLeaderboard.isCrossGroupChallenge || false,
        },
        isHidden: {
          value: !duplicating ? challenge.isHidden : true,
        },
        isOnline: {
          value: challenge.isOnline,
        },
        membersOf: {
          value: get(challengeRegistrationConditions, CONSTANTS.MEMBERS_OF, []),
          isDisabled:
            (challengeType === CONSTANTS.CHARITY || challengeType === CONSTANTS.GROUP) &&
            !duplicating,
        },
        excludedFrom: {
          value: get(challengeRegistrationConditions, CONSTANTS.EXCLUDED_FROM, []),
          isDisabled:
            (challengeType === CONSTANTS.CHARITY || challengeType === CONSTANTS.GROUP) &&
            !duplicating,
        },
        isCodeRestricted: {
          value: challenge.isCodeRestricted || false,
        },
        restrictedAccessCodes: {
          value: !duplicating ? challenge.restrictedAccessCodes || [] : [],
        },
        registration: challengeRegistration,
      },
      position: {
        value: getPropertyType({
          data: challengeDisplay,
          defaultLanguage,
          key: CONSTANTS.POSITION,
          defaultValue: false,
        }),
      },
      highlight: {
        [CONSTANTS.HIGHLIGHT_CTA]: formattedDisplayForform[CONSTANTS.HIGHLIGHT_CTA],
        highlight: {
          value: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HIGHLIGHT,
            defaultValue: false,
          }),
        },
        highlightBackgroundImage: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HIGHLIGHT_BACKGROUND_IMAGE,
          }),
          file: null,
        },
      },
      promote: {
        promote: {
          value: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.PROMOTE,
            defaultValue: false,
          }),
        },
        promoteDesktop: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.PROMOTE_DESKTOP,
          }),
        },
        promoteMobile: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.PROMOTE_MOBILE,
          }),
        },
        promoteLogo: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.PROMOTE_LOGO,
          }),
        },
        [CONSTANTS.PROMOTE_TEXT]: formattedDisplayForform[CONSTANTS.PROMOTE_TEXT],
      },
      charity: {
        moneyMultiplier: {
          ...basicChallenge.charity.moneyMultiplier,
          value: formattedFunctions.checkDisplayData({
            currentLanguage: defaultLanguage,
            display: challengeDisplay,
            languages,
            type: CONSTANTS.MONEY_MULITPLIER,
          }),
        },
        hashtag: {
          value: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HASHTAG,
          }),
        },
        rightIllu: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: `${CONSTANTS.RIGHT_ILLU}.image`,
          }),
          file: null,
        },
        headerLogo: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HEADER_LOGO,
          }),
          file: null,
        },
        modalConfirm: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: `${CONSTANTS.MODAL_CONFIRM}.image`,
          }),
          file: null,
        },
      },
      group: {
        usersAutoRegistration: {
          value: get(challenge.registration, CONSTANTS.USERS_AUTO_REGISTRATION, false),
        },
        hashtag: {
          value: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HASHTAG,
          }),
        },
        headerLogo: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.HEADER_LOGO,
          }),
          file: null,
        },
        modalConfirm: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: `${CONSTANTS.MODAL_CONFIRM}.image`,
          }),
          file: null,
        },
      },
      instantWin: {
        remaining: {
          value: get(challenge.instantWin, CONSTANTS.REMAINING, null),
        },
        available: {
          value: get(challenge.instantWin, CONSTANTS.AVAILABLE, null),
        },
        numberprizes: {
          ...basicChallenge.instantWin.numberprizes,
          value: get(challenge.instantWin, CONSTANTS.TOTAL, null),
          isDisabled: challengeStart < today && !duplicating,
        },
        startAvailableAt: {
          ...basicChallenge.instantWin.startAvailableAt,
          value: challengeStart,
          isDisabled: challengeStart < today && !duplicating,
        },
        endAvailableAt: {
          ...basicChallenge.instantWin.endAvailableAt,
          value: challengeEnd,
          isDisabled: challengeStart < today && !duplicating,
        },
      },
      content: {
        [CONSTANTS.TITLE]: formattedDisplayForform[CONSTANTS.TITLE],
        [CONSTANTS.SLUG]: formattedDisplayForform[CONSTANTS.SLUG],
        [CONSTANTS.TYPE]: formattedDisplayForform[CONSTANTS.TYPE],
        [CONSTANTS.CONTENT]: formattedDisplayForform[CONSTANTS.CONTENT],
        [CONSTANTS.EXCERPT]: formattedDisplayForform[CONSTANTS.EXCERPT],
        [CONSTANTS.REWARD_NAME]: formattedDisplayForform[CONSTANTS.REWARD_NAME],
        [CONSTANTS.REWARD_CONDITIONS]: formattedDisplayForform[CONSTANTS.REWARD_CONDITIONS],
      },
      customTagColor: {
        value: challenge.customTagColor,
      },
      images: {
        cover: {
          url: getPropertyType({ data: challengeDisplay, defaultLanguage, key: CONSTANTS.COVER }),
          file: null,
        },
        indexCover: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.INDEX_COVER,
          }),
          file: null,
        },
        badge: {
          url: getPropertyType({ data: challengeDisplay, defaultLanguage, key: CONSTANTS.BADGE }),
          file: null,
        },
        rewardCover: {
          url: getPropertyType({
            data: challengeDisplay,
            defaultLanguage,
            key: CONSTANTS.REWARD_COVER,
          }),
          file: null,
        },
      },
      emails: {
        ...formattedFunctions.getChallengeEmails({
          display: challengeDisplay,
          languages,
          type: CONSTANTS.CRM,
        }),
      },
      objectives: {
        ...basicChallenge.objectives,
        combinations: {
          value: formattedFunctions.getChallengeCombinationsConditions(challengeConditions),
        },
        [CONSTANTS.OBJECTIVE]: formattedDisplayForform[CONSTANTS.OBJECTIVE],
      },
      bonus: {
        _id: get(challengeBonusConfig, '_id', null),
        activated: {
          value: !!get(challengeBonusConfig, CONSTANTS.POINTS, null),
        },
        points: { value: get(challengeBonusConfig, CONSTANTS.POINTS, null) },
      },
      crm: {
        hasEmailFinishers: { value: get(challengeCrm, CONSTANTS.CRM_HAS_EMAIL_FINISHERS, false) },
        hasPushFinishers: { value: get(challengeCrm, CONSTANTS.CRM_HAS_PUSH_FINISHERS, false) },
        hasEmailWinners: { value: get(challengeCrm, CONSTANTS.CRM_HAS_EMAIL_WINNERS, false) },
        hasEmailRegistration: {
          value: get(challengeCrm, CONSTANTS.CRM_HAS_EMAIL_REGISTRATION, false),
        },
      },
      products: formattedProducts.length ? formattedProducts : basicChallenge.products,
      segmentation: {
        isEnabled: challenge.segmentation?.isEnabled,
        segments: challenge.segmentation?.segments || [],
      },
    },
    challenge,
  };

  return parsed;
};
