export var loadSelectedUserClients = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS',
        payload: payload
    });
};
export var loadUserClientsSuccess = function (userClients) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_SUCCESS',
        payload: { userClients: userClients }
    });
};
export var loadUserClientsError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_CLIENTS_ERROR',
        payload: { error: error }
    });
};
export var setCurrentUserClientId = function (clientId) {
    return ({
        type: 'OLY_ENGINE_STORE_SET_CURRENT_USER_CLIENT_ID',
        payload: { clientId: clientId }
    });
};
export var resetUserClients = function () {
    return ({
        type: 'OLY_ENGINE_STORE_RESET_USER_CLIENTS'
    });
};
export var loadOneUserClient = function (payload) {
    return ({
        type: 'OLY_LOAD_ONE_USER_CLIENT',
        payload: payload
    });
};
export var loadOneUserClientSuccess = function (userClient) {
    return ({
        type: 'OLY_LOAD_ONE_USER_CLIENT_SUCCESS',
        payload: { userClient: userClient }
    });
};
export var loadOneUserClientError = function (error) {
    return ({
        type: 'OLY_LOAD_ONE_USER_CLIENT_ERROR',
        payload: { error: error }
    });
};
export var updateUserClientSettings = function (payload) {
    return ({
        type: 'OLY_UPDATE_USER_CLIENT_SETTINGS',
        payload: payload
    });
};
export var updateUserClientSettingsSuccess = function (settings) {
    return ({
        type: 'OLY_UPDATE_USER_CLIENT_SETTINGS_SUCCESS',
        payload: { settings: settings }
    });
};
export var updateUserClientSettingsError = function (error) {
    return ({
        type: 'OLY_UPDATE_USER_CLIENT_SETTINGS_ERROR',
        payload: { error: error }
    });
};
export default {
    loadSelectedUserClients: loadSelectedUserClients,
    loadUserClientsSuccess: loadUserClientsSuccess,
    loadUserClientsError: loadUserClientsError,
    setCurrentUserClientId: setCurrentUserClientId,
    resetUserClients: resetUserClients,
    loadOneUserClient: loadOneUserClient,
    loadOneUserClientSuccess: loadOneUserClientSuccess,
    loadOneUserClientError: loadOneUserClientError,
    updateUserClientSettings: updateUserClientSettings,
    updateUserClientSettingsSuccess: updateUserClientSettingsSuccess,
    updateUserClientSettingsError: updateUserClientSettingsError
};
