var deleteUserRedeemReward = function (payload) {
    return ({
        type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD',
        payload: payload
    });
};
var deleteUserRedeemRewardSuccess = function (userId) {
    return ({
        type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_SUCCESS',
        payload: userId
    });
};
var deleteUserRedeemRewardError = function (error) {
    return ({
        type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_ERROR',
        payload: { error: error }
    });
};
var deleteUserRedeemRewardReset = function () {
    return ({
        type: 'OLY_ENGINE_DELETE_USER_REDEEM_REWARD_RESET'
    });
};
export default {
    deleteUserRedeemReward: deleteUserRedeemReward,
    deleteUserRedeemRewardSuccess: deleteUserRedeemRewardSuccess,
    deleteUserRedeemRewardError: deleteUserRedeemRewardError,
    deleteUserRedeemRewardReset: deleteUserRedeemRewardReset
};
