import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
export var userActivitiesStateSelector = createSelector(userStateSelector, function (user) { return user.activities; });
// LOAD USER ACTIVITIES
export var loadUserActivitiesSelector = createSelector(userActivitiesStateSelector, function (activities) { return activities.loadUserActivities; });
export var loadUserActivitiesDataSelector = createSelector(loadUserActivitiesSelector, function (loadUserActivities) { return loadUserActivities.data; });
export var loadUserActivitiesMetaDataSelector = createSelector(loadUserActivitiesSelector, function (loadUserActivities) { return loadUserActivities.metadata; });
export var loadUserActivitiesIsLoadingSelector = createSelector(loadUserActivitiesSelector, function (loadUserActivities) { return loadUserActivities.isLoading; });
export var loadUserActivitiesErrorSelector = createSelector(loadUserActivitiesSelector, function (loadUserActivities) { return loadUserActivities.error; });
// REPLAY USER ACTIVITY
export var replayUserActivitySelector = createSelector(userActivitiesStateSelector, function (activities) { return activities.replayUserActivity; });
export var replayedUserActivityIdSelector = createSelector(replayUserActivitySelector, function (replayUserActivity) { return replayUserActivity.replayedActivityId; });
export var replayUserActivityIsLoadingSelector = createSelector(replayUserActivitySelector, function (replayUserActivity) { return replayUserActivity.isLoading; });
export var replayUserActivityErrorSelector = createSelector(replayUserActivitySelector, function (replayUserActivity) { return replayUserActivity.error; });
// CREATE USER ACTIVITY
export var createUserActivitySelector = createSelector(userActivitiesStateSelector, function (activities) { return activities.createUserActivity; });
export var createdUserActivitySelector = createSelector(createUserActivitySelector, function (createUserActivity) { return createUserActivity.createdActivity; });
export var createUserActivityIsLoadingSelector = createSelector(createUserActivitySelector, function (createUserActivity) { return createUserActivity.isLoading; });
export var createUserActivityErrorSelector = createSelector(createUserActivitySelector, function (createUserActivity) { return createUserActivity.error; });
// EDIT USER ACTIVITY
export var editUserActivitySelector = createSelector(userActivitiesStateSelector, function (activities) { return activities.editUserActivity; });
export var editedUserActivityIdSelector = createSelector(editUserActivitySelector, function (editUserActivity) { return editUserActivity.editedActivityId; });
export var editUserActivityIsLoadingSelector = createSelector(editUserActivitySelector, function (editUserActivity) { return editUserActivity.isLoading; });
export var editUserActivityErrorSelector = createSelector(editUserActivitySelector, function (editUserActivity) { return editUserActivity.error; });
// DELETE USER ACTIVITY
export var deleteUserActivitySelector = createSelector(userActivitiesStateSelector, function (activities) { return activities.deleteUserActivity; });
export var deletedUserActivityIdSelector = createSelector(deleteUserActivitySelector, function (deleteUserActivity) { return deleteUserActivity.deletedActivityId; });
export var deleteUserActivityIsLoadingSelector = createSelector(deleteUserActivitySelector, function (deleteUserActivity) { return deleteUserActivity.isLoading; });
export var deleteUserActivityErrorSelector = createSelector(deleteUserActivitySelector, function (deleteUserActivity) { return deleteUserActivity.error; });
export default {
    loadUserActivitiesDataSelector: loadUserActivitiesDataSelector,
    loadUserActivitiesMetaDataSelector: loadUserActivitiesMetaDataSelector,
    loadUserActivitiesIsLoadingSelector: loadUserActivitiesIsLoadingSelector,
    loadUserActivitiesErrorSelector: loadUserActivitiesErrorSelector,
    replayedUserActivityIdSelector: replayedUserActivityIdSelector,
    replayUserActivityIsLoadingSelector: replayUserActivityIsLoadingSelector,
    replayUserActivityErrorSelector: replayUserActivityErrorSelector,
    createdUserActivitySelector: createdUserActivitySelector,
    createUserActivityIsLoadingSelector: createUserActivityIsLoadingSelector,
    createUserActivityErrorSelector: createUserActivityErrorSelector,
    editedUserActivityIdSelector: editedUserActivityIdSelector,
    editUserActivityIsLoadingSelector: editUserActivityIsLoadingSelector,
    editUserActivityErrorSelector: editUserActivityErrorSelector,
    deletedUserActivityIdSelector: deletedUserActivityIdSelector,
    deleteUserActivityIsLoadingSelector: deleteUserActivityIsLoadingSelector,
    deleteUserActivityErrorSelector: deleteUserActivityErrorSelector
};
