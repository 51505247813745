import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid ${p => p.theme.colors.grey40};
  justify-content: center;
  padding: 48px;
  width: 100%;
  max-width: 420px;
`;

export const LogoContainer = styled.div`
  justify-content: center;
  height: 96px;
  margin-bottom: 16px;
  display: flex;
`;

export const MainTextContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  align-items: center;
`;

export const MainText = styled.p`
  font-family: ${p => p.theme.fonts.primary};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
`;

export const SecondaryText = styled(MainText)`
  font-size: 16px;
  font-weight: normal;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  font-family: ${p => p.theme.fonts.primary};
`;

export const ButtonText = styled(MainText)`
  font-size: 12px;
  font-weight: normal;
`;

export const WarningIconContainer = styled.div`
  margin-right: 8px;
`;
