// LOAD USER ACTIVITIES
export var loadUserActivities = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES',
        payload: payload
    });
};
export var loadUserActivitiesSuccess = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_SUCCESS',
        payload: payload
    });
};
export var loadUserActivitiesError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_ERROR',
        payload: { error: error }
    });
};
export var resetUserActivities = function () {
    return ({
        type: 'OLY_ENGINE_STORE_RESET_USER_ACTIVITIES'
    });
};
// REPLAY USER ACTIVITY
export var replayUserActivity = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY',
        payload: payload
    });
};
export var replayUserActivitySuccess = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_SUCCESS',
        payload: payload
    });
};
export var replayUserActivityError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_ERROR',
        payload: { error: error }
    });
};
export var replayUserActivitiesReset = function () {
    return ({
        type: 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_RESET'
    });
};
// CREATE USER ACTIVITY
export var createUserActivity = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY',
        payload: payload
    });
};
export var createUserActivitySuccess = function () {
    return ({
        type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_SUCCESS'
    });
};
export var createUserActivityError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_ERROR',
        payload: { error: error }
    });
};
export var createUserActivityReset = function () {
    return ({
        type: 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_RESET'
    });
};
// EDIT USER ACTIVITY
export var editUserActivity = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY',
        payload: payload
    });
};
export var editUserActivitySuccess = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_SUCCESS',
        payload: payload
    });
};
export var editUserActivityError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_ERROR',
        payload: { error: error }
    });
};
export var editUserActivityReset = function () {
    return ({
        type: 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_RESET'
    });
};
// DELETE USER ACTIVITY
export var deleteUserActivity = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY',
        payload: payload
    });
};
export var deleteUserActivitySuccess = function (payload) {
    return ({
        type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_SUCCESS',
        payload: payload
    });
};
export var deleteUserActivityError = function (error) {
    return ({
        type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_ERROR',
        payload: { error: error }
    });
};
export var deleteUserActivityReset = function () {
    return ({
        type: 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_RESET'
    });
};
export default {
    loadUserActivities: loadUserActivities,
    loadUserActivitiesSuccess: loadUserActivitiesSuccess,
    loadUserActivitiesError: loadUserActivitiesError,
    resetUserActivities: resetUserActivities,
    replayUserActivity: replayUserActivity,
    replayUserActivitySuccess: replayUserActivitySuccess,
    replayUserActivityError: replayUserActivityError,
    replayUserActivitiesReset: replayUserActivitiesReset,
    createUserActivity: createUserActivity,
    createUserActivitySuccess: createUserActivitySuccess,
    createUserActivityError: createUserActivityError,
    createUserActivityReset: createUserActivityReset,
    editUserActivity: editUserActivity,
    editUserActivitySuccess: editUserActivitySuccess,
    editUserActivityError: editUserActivityError,
    editUserActivityReset: editUserActivityReset,
    deleteUserActivity: deleteUserActivity,
    deleteUserActivitySuccess: deleteUserActivitySuccess,
    deleteUserActivityError: deleteUserActivityError,
    deleteUserActivityReset: deleteUserActivityReset
};
