var loadUserInfo = function (payload) {
    var _a = payload || {}, userId = _a.userId, clientId = _a.clientId, withGroup = _a.withGroup;
    return {
        type: 'OLY_LOAD_USER_INFO',
        payload: { userId: userId, clientId: clientId, withGroup: withGroup }
    };
};
var loadUserInfoSuccess = function (userInfo) {
    return {
        type: 'OLY_LOAD_USER_INFO_SUCCESS',
        payload: { userInfo: userInfo }
    };
};
var loadUserInfoError = function (error) {
    return {
        type: 'OLY_LOAD_USER_INFO_ERROR',
        payload: { error: error }
    };
};
var loadUserInfoReset = function () {
    return {
        type: 'OLY_LOAD_USER_INFO_RESET'
    };
};
var editUserInfo = function (payload) {
    return {
        type: 'OLY_EDIT_USER_INFO',
        payload: payload
    };
};
var editUserInfoSuccess = function (editedUserId) {
    return {
        type: 'OLY_EDIT_USER_INFO_SUCCESS',
        payload: { editedUserId: editedUserId }
    };
};
var editUserInfoError = function (error) {
    return {
        type: 'OLY_EDIT_USER_INFO_ERROR',
        payload: { error: error }
    };
};
var editUserInfoReset = function () {
    return {
        type: 'OLY_EDIT_USER_INFO_RESET'
    };
};
var editUserClient = function (payload) {
    return {
        type: 'OLY_EDIT_USER_CLIENT',
        payload: payload
    };
};
var editUserClientSuccess = function () {
    return {
        type: 'OLY_EDIT_USER_CLIENT_SUCCESS'
    };
};
var editUserClientError = function (error) {
    return {
        type: 'OLY_EDIT_USER_CLIENT_ERROR',
        payload: { error: error }
    };
};
var editUserClientReset = function () {
    return {
        type: 'OLY_EDIT_USER_CLIENT_RESET'
    };
};
var deleteUserInfoGroup = function (payload) {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP',
        payload: payload
    };
};
var deleteUserInfoGroupSuccess = function (deletedUserGroupId) {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_SUCCESS',
        payload: { deletedUserGroupId: deletedUserGroupId }
    };
};
var deleteUserInfoGroupError = function (error) {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_ERROR',
        payload: { error: error }
    };
};
var deleteUserInfoGroupReset = function () {
    return {
        type: 'OLY_DELETE_USER_INFO_GROUP_RESET'
    };
};
var deleteUserInfoClient = function (payload) {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT',
        payload: payload
    };
};
var deleteUserInfoClientSuccess = function (deletedUserClientId) {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_SUCCESS',
        payload: { deletedUserClientId: deletedUserClientId }
    };
};
var deleteUserInfoClientError = function (error) {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_ERROR',
        payload: { error: error }
    };
};
var deleteUserInfoClientReset = function () {
    return {
        type: 'OLY_DELETE_USER_INFO_CLIENT_RESET'
    };
};
var deleteUser = function (payload) {
    return {
        type: 'OLY_DELETE_USER',
        payload: payload
    };
};
var deleteUserSuccess = function (deletedUserId) {
    return {
        type: 'OLY_DELETE_USER_SUCCESS',
        payload: { deletedUserId: deletedUserId }
    };
};
var deleteUserError = function (error) {
    return {
        type: 'OLY_DELETE_USER_ERROR',
        payload: { error: error }
    };
};
var deleteUserReset = function () {
    return {
        type: 'OLY_DELETE_USER_RESET'
    };
};
export default {
    loadUserInfo: loadUserInfo,
    loadUserInfoSuccess: loadUserInfoSuccess,
    loadUserInfoError: loadUserInfoError,
    loadUserInfoReset: loadUserInfoReset,
    editUserInfo: editUserInfo,
    editUserInfoSuccess: editUserInfoSuccess,
    editUserInfoError: editUserInfoError,
    editUserInfoReset: editUserInfoReset,
    editUserClient: editUserClient,
    editUserClientSuccess: editUserClientSuccess,
    editUserClientError: editUserClientError,
    editUserClientReset: editUserClientReset,
    deleteUserInfoGroup: deleteUserInfoGroup,
    deleteUserInfoGroupSuccess: deleteUserInfoGroupSuccess,
    deleteUserInfoGroupError: deleteUserInfoGroupError,
    deleteUserInfoGroupReset: deleteUserInfoGroupReset,
    deleteUserInfoClient: deleteUserInfoClient,
    deleteUserInfoClientSuccess: deleteUserInfoClientSuccess,
    deleteUserInfoClientError: deleteUserInfoClientError,
    deleteUserInfoClientReset: deleteUserInfoClientReset,
    deleteUser: deleteUser,
    deleteUserSuccess: deleteUserSuccess,
    deleteUserError: deleteUserError,
    deleteUserReset: deleteUserReset
};
