var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers } from 'redux';
export var loadUserInfoState = {
    userInfo: null,
    isLoading: false,
    error: null
};
var loadUserInfoReducer = function (state, action) {
    if (state === void 0) { state = loadUserInfoState; }
    switch (action.type) {
        case 'OLY_LOAD_USER_INFO':
            return __assign(__assign({}, loadUserInfoState), { isLoading: true });
        case 'OLY_LOAD_USER_INFO_SUCCESS':
            return __assign(__assign({}, loadUserInfoState), { userInfo: action.payload.userInfo });
        case 'OLY_LOAD_USER_INFO_ERROR':
            return __assign(__assign({}, loadUserInfoState), { error: action.payload.error });
        case 'OLY_LOAD_USER_INFO_RESET':
            return loadUserInfoState;
        default:
            return state;
    }
};
export var editUserClientState = {
    success: null,
    isLoading: false,
    error: null
};
var editUserClientReducer = function (state, action) {
    if (state === void 0) { state = editUserClientState; }
    switch (action.type) {
        case 'OLY_EDIT_USER_CLIENT':
            return __assign(__assign({}, editUserClientState), { isLoading: true });
        case 'OLY_EDIT_USER_CLIENT_SUCCESS':
            return __assign(__assign({}, editUserClientState), { success: true });
        case 'OLY_EDIT_USER_CLIENT_ERROR':
            return __assign(__assign({}, editUserClientState), { error: action.payload.error });
        case 'OLY_EDIT_USER_CLIENT_RESET':
            return editUserClientState;
        default:
            return state;
    }
};
export var editUserInfoState = {
    editedUserId: null,
    isLoading: false,
    error: null
};
var editUserInfoReducer = function (state, action) {
    if (state === void 0) { state = editUserInfoState; }
    switch (action.type) {
        case 'OLY_EDIT_USER_INFO':
            return __assign(__assign({}, editUserInfoState), { isLoading: true });
        case 'OLY_EDIT_USER_INFO_SUCCESS':
            return __assign(__assign({}, editUserInfoState), { editedUserId: action.payload.editedUserId });
        case 'OLY_EDIT_USER_INFO_ERROR':
            return __assign(__assign({}, editUserInfoState), { error: action.payload.error });
        case 'OLY_EDIT_USER_INFO_RESET':
            return editUserInfoState;
        default:
            return state;
    }
};
export var deleteUserInfoGroupState = {
    deletedUserGroupId: null,
    isLoading: false,
    error: null
};
var deleteUserInfoGroupReducer = function (state, action) {
    if (state === void 0) { state = deleteUserInfoGroupState; }
    switch (action.type) {
        case 'OLY_DELETE_USER_INFO_GROUP':
            return __assign(__assign({}, deleteUserInfoGroupState), { isLoading: true });
        case 'OLY_DELETE_USER_INFO_GROUP_SUCCESS':
            return __assign(__assign({}, deleteUserInfoGroupState), { deletedUserGroupId: action.payload.deletedUserGroupId });
        case 'OLY_DELETE_USER_INFO_GROUP_ERROR':
            return __assign(__assign({}, deleteUserInfoGroupState), { error: action.payload.error });
        case 'OLY_DELETE_USER_INFO_GROUP_RESET':
            return deleteUserInfoGroupState;
        default:
            return state;
    }
};
export var deleteUserInfoClientState = {
    deletedUserClientId: null,
    isLoading: false,
    error: null
};
var deleteUserInfoClientReducer = function (state, action) {
    if (state === void 0) { state = deleteUserInfoClientState; }
    switch (action.type) {
        case 'OLY_DELETE_USER_INFO_CLIENT':
            return __assign(__assign({}, deleteUserInfoClientState), { isLoading: true });
        case 'OLY_DELETE_USER_INFO_CLIENT_SUCCESS':
            return __assign(__assign({}, deleteUserInfoClientState), { deletedUserClientId: action.payload.deletedUserClientId });
        case 'OLY_DELETE_USER_INFO_CLIENT_ERROR':
            return __assign(__assign({}, deleteUserInfoClientState), { error: action.payload.error });
        case 'OLY_DELETE_USER_INFO_CLIENT_RESET':
            return deleteUserInfoClientState;
        default:
            return state;
    }
};
export var deleteUserState = {
    deletedUserId: null,
    isLoading: false,
    error: null
};
var deleteUserReducer = function (state, action) {
    if (state === void 0) { state = deleteUserState; }
    switch (action.type) {
        case 'OLY_DELETE_USER':
            return __assign(__assign({}, deleteUserState), { isLoading: true });
        case 'OLY_DELETE_USER_SUCCESS':
            return __assign(__assign({}, deleteUserState), { deletedUserId: action.payload.deletedUserId });
        case 'OLY_DELETE_USER_ERROR':
            return __assign(__assign({}, deleteUserState), { error: action.payload.error });
        case 'OLY_DELETE_USER_RESET':
            return deleteUserState;
        default:
            return state;
    }
};
export var userInfoState = {
    loadUserInfo: loadUserInfoState,
    editUserInfo: editUserInfoState,
    editUserClient: editUserClientState,
    deleteUserInfoGroup: deleteUserInfoGroupState,
    deleteUserInfoClient: deleteUserInfoClientState,
    deleteUser: deleteUserState
};
export default combineReducers({
    loadUserInfo: loadUserInfoReducer,
    editUserInfo: editUserInfoReducer,
    editUserClient: editUserClientReducer,
    deleteUserInfoGroup: deleteUserInfoGroupReducer,
    deleteUserInfoClient: deleteUserInfoClientReducer,
    deleteUser: deleteUserReducer
});
