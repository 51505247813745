import React from 'react';

import { colors } from 'styles/common';

const Cross = ({ color = colors.grey60 }) => (
  <svg width={12} height={12}>
    <path
      d="M8.121 6l3.44-3.44A1.5 1.5 0 1 0 9.44.44L6 3.878 2.56.439A1.5 1.5 0 1 0 .44 2.56L3.878 6 .439 9.44a1.5 1.5 0 1 0 2.121 2.12L6 8.122l3.44 3.44c.292.293.676.44 1.06.44s.768-.147 1.06-.44a1.5 1.5 0 0 0 0-2.121L8.122 6z"
      fill={color}
      fillRule="nonzero"
    />
  </svg>
);

export default Cross;
