import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import RoundedLight from 'components/RoundedLight';
import Success from 'icons/Success';
import Info from 'icons/Info';
import Alert from 'icons/Alert';
import Cross from 'icons/Cross';

import NotificationProgressBar from './NotificationProgressBar';

const DEFAULT_DURATION = 4000;

// Note: might need to allow fully customizable
// icons and colors if we need more types
const TYPES = {
  success: {
    bgIcon: '#7ED321',
    icon: <Success />,
    colorProgressBar: '#7ED321',
  },
  info: {
    bgIcon: '#00ABE5',
    icon: <Info />,
    colorProgressBar: '#00ABE5',
  },
  warning: {
    bgIcon: '#FFC72F',
    icon: <Alert />,
    colorProgressBar: '#FFC72F',
  },
  error: {
    bgIcon: '#F24949',
    icon: <Alert />,
    colorProgressBar: '#F24949',
  },
  online: {
    bgIcon: '#FFFFFF',
    icon: <RoundedLight status="online" />,
    colorProgressBar: '#7ED321',
  },
  offline: {
    bgIcon: '#FFFFFF',
    icon: <RoundedLight status="offline" />,
    colorProgressBar: '#F24949',
  },
};

const Container = styled.div`
  border-radius: 3px;
  background-color: grey;
  padding: 15px 5px;
  box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 8px 16px -4px rgba(9, 30, 66, 0.25);
  background-color: #ffffff;
  display: flex;
  position: relative;
`;

const TypeContainer = styled.div`
  padding: 0 10px;
`;

const Circle = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: ${p => p.bgIcon};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  width: 80%;
`;
const CrossContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;

const Title = styled.div`
  color: #1e1e1c;
  font-weight: bold;
`;
const Content = styled.div`
  margin-top: 5px;
`;

const NotificationItem = ({ title, content, type, onClose, duration, autoClose }) => {
  const { bgIcon, icon, colorProgressBar } = TYPES[type];

  return (
    <Container>
      {type && (
        <TypeContainer>
          <Circle bgIcon={bgIcon}>{icon}</Circle>
        </TypeContainer>
      )}
      <ContentContainer>
        {title && <Title>{title}</Title>}
        {content && <Content>{content}</Content>}
      </ContentContainer>
      {onClose && (
        <CrossContainer onClick={onClose}>
          <Cross />
        </CrossContainer>
      )}
      {autoClose && <NotificationProgressBar color={colorProgressBar} duration={duration} />}
    </Container>
  );
};

NotificationItem.propTypes = {
  content: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
  autoClose: PropTypes.bool,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'online', 'offline']),
  duration: PropTypes.number,
};

NotificationItem.defaultProps = {
  title: null,
  content: null,
  type: null,
  onClose: null,
  autoClose: true,
  duration: DEFAULT_DURATION,
};

export default NotificationItem;
