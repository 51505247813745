import { combineReducers } from 'redux';
import userActivitiesReducers from './activities/reducers';
import userAppsReducers from './apps/reducers';
import userChallengesReducers from './challenges/reducers';
import userClientsReducers from './clients/reducers';
import userFeedReducers from './feed/reducers';
import userInfoReducers from './info/reducers';
import userCoreReducers from './core/reducers';
import userRewardsReducers from './rewards/reducers';
export default combineReducers({
    activities: userActivitiesReducers,
    apps: userAppsReducers,
    challenges: userChallengesReducers,
    clients: userClientsReducers,
    feed: userFeedReducers,
    info: userInfoReducers,
    core: userCoreReducers,
    rewards: userRewardsReducers
});
