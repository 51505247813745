var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers } from 'redux';
export var uploadProfilePictureState = {
    hasBeenUploaded: false,
    isLoading: false,
    error: null
};
var uploadProfilePictureReducer = function (state, action) {
    if (state === void 0) { state = uploadProfilePictureState; }
    switch (action.type) {
        case 'OLY_UPLOAD_PROFILE_PICTURE':
            return __assign(__assign({}, uploadProfilePictureState), { isLoading: true });
        case 'OLY_UPLOAD_PROFILE_PICTURE_SUCCESS':
            return __assign(__assign({}, uploadProfilePictureState), { hasBeenUploaded: true });
        case 'OLY_UPLOAD_PROFILE_PICTURE_ERROR':
            return __assign(__assign({}, uploadProfilePictureState), { error: action.payload.error });
        case 'OLY_UPLOAD_PROFILE_PICTURE_RESET':
            return uploadProfilePictureState;
        default:
            return state;
    }
};
export var userCoreState = {
    uploadProfilePicture: uploadProfilePictureState
};
export default combineReducers({
    uploadProfilePicture: uploadProfilePictureReducer
});
