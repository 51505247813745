/* eslint-disable no-use-before-define */
import get from 'lodash/get';
import omit from 'lodash/omit';

import * as CONSTANTS from 'constants/challenge';

export const hasOnlyNumber = value => {
  const hasOnlyNumber = /^[0-9]+([.][0-9]+)*$/;
  return value && value.length && hasOnlyNumber.test(value);
};

export const isSlugValid = value => {
  const isSlugValid = /^[a-z0-9-]+$/;
  return value && value.length && isSlugValid.test(value);
};

export const isHexColor = value => {
  const isHex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
  return value && value.length && isHex.test(value);
};

/**
 * @function checkDisplayData
 * Return the value corresponding to the current type and language.
 *
 * If it is an old challenge, the display data is not yet parsed with all the languages.
 * So when the current language corresponding to the default language we return the value,
 *
 *
 * @param {string} currentLanguage
 * @param {Object} display
 * @param {Array.<string>} languages
 * @param {string} type
 * @param {string} defaultLanguage
 *
 * @returns {string}
 */
export const checkDisplayData = ({
  currentLanguage,
  display,
  languages,
  type,
  defaultLanguage,
}) => {
  if (!display) {
    return null;
  }

  const displayType = display[type];

  // New format by languages => { fr: null, en: null ,es: '' , ... }
  if (display[currentLanguage]) {
    return display[currentLanguage][type];
  }

  //  Old format by types => { title: 'titleValue', content: 'contentValue', ... }
  if (displayType) {
    const indexCurrentLanguage = languages.findIndex(l => {
      return l === currentLanguage;
    });

    return languages[indexCurrentLanguage] === defaultLanguage ? displayType : null;
  }
};

/**
 * @function getInfosByLanguage
 *
 * @param {Object} display
 * @param {Array.<string>} languages
 * @param {string} type
 * @param {string} defaultLanguage
 *
 * @returns {Object} Returns the value of the type depending on different language
 *
 * @exemple
 * //return { en: "animal", es: null, fr: null, it: null, nl: null, pt: null}
 */
export const getInfosByLanguage = ({ infos, languages, type, defaultLanguage }) => {
  return languages.reduce((acc, curr) => {
    const displayData = formattedFunctions.checkDisplayData({
      currentLanguage: curr,
      display: infos,
      languages,
      type,
      defaultLanguage,
    });
    acc[curr] = infos ? displayData : null;
    return acc;
  }, {});
};

/**
 * @function getChallengeDisplay
 *
 * @param {Array.<string>} displayTypes
 * @param {Object} display
 * @param {Array.<string>} languages
 * @param {string} defaultLanguage
 *
 * @returns {Object}
 *
 * @exemple
 * // returns { goal: { value: { fr: null, en: null } }, objectives: { value: { fr: null, en: null } } }
 */
const getChallengeDisplay = ({ types, display, languages, defaultLanguage }) => {
  return types.reduce((acc, curr) => {
    acc[curr] = {
      value: formattedFunctions.getInfosByLanguage({
        infos: display,
        languages,
        type: curr,
        defaultLanguage,
      }),
    };
    return acc;
  }, {});
};

/**
 * @function getConditionsForDisplay
 *
 * @param {Object} combination
 *
 * @returns {Array} Returns the array with all the conditions keys and value
 */

export const getConditionsForDisplay = combination => {
  return Object.keys(combination).map(key => {
    if (key === 'unit') {
      return { key: 'noObjective', value: combination[key] };
    }

    return { key, value: combination[key] };
  });
};

/**
 * @function getChallengeCombinationsConditions
 *
 * @param {Object} conditions - the conditions for the challenge
 *
 * @returns {Array} Returns the object with all the conditions and its value
 *
 */
export const getChallengeCombinationsConditions = conditions => {
  const combinationsValue = get(conditions, 'combinations', []);

  const combinations = combinationsValue.length ? combinationsValue : [conditions];

  const combinationsDisplayFormat = combinations.reduce((acc, curr) => {
    const conditions = getConditionsForDisplay(
      omit(curr, ['types', 'combinations', 'conditions', 'allowManual', 'noObjective']),
    );

    const { types, allowManual } = curr;

    const combinationsKeys = { types, ...(allowManual ? { allowManual } : {}) };

    return [...acc, { ...combinationsKeys, conditions }];
  }, []);

  return combinationsDisplayFormat;
};

/**
 * @function getChallengeEmails
 *
 * @param {Object} challengeDisplay
 * @param {Array.<string>} languages
 * @param {string} type
 *
 * @returns {Object}
 */
export const getChallengeEmails = ({ display, languages, type }) => {
  const crm = formattedFunctions.getInfosByLanguage({ infos: display, languages, type });
  const types = [
    CONSTANTS.FINISHERS,
    CONSTANTS.FINISHERS_PUSH,
    CONSTANTS.WINNERS,
    CONSTANTS.REGISTRATION,
  ];
  const subTypes = [CONSTANTS.CONTENT, CONSTANTS.CTA_TEXT, CONSTANTS.CTA_LINK];
  const subTypesPushNotifications = [CONSTANTS.TITLE, CONSTANTS.MESSAGE];

  return types.reduce((acc, _, i) => {
    const contentSubTypes = (types[i] === CONSTANTS.FINISHERS_PUSH
      ? subTypesPushNotifications
      : subTypes
    ).reduce((accumulator, _, index) => {
      const contentTypes = formattedFunctions.getInfosByLanguage({
        infos: crm,
        languages,
        type: types[i],
      });

      accumulator[
        (types[i] === CONSTANTS.FINISHERS_PUSH ? subTypesPushNotifications : subTypes)[index]
      ] = {
        value: getInfosByLanguage({
          infos: contentTypes,
          languages,
          type: (types[i] === CONSTANTS.FINISHERS_PUSH ? subTypesPushNotifications : subTypes)[
            index
          ],
        }),
      };
      return accumulator;
    }, {});

    acc[types[i]] = {
      ...contentSubTypes,
      ...(types[i] !== CONSTANTS.FINISHERS_PUSH
        ? {
            headerImage: {
              url: display.crm ? display.crm[types[i]] && display.crm[types[i]].headerImage : null,
              file: null,
            },
          }
        : {}),
    };
    return acc;
  }, {});
};

/**
 * @function countryValues
 *
 * @param {Array.<string>} languages
 *
 * @returns {Object}
 */
export const countryValues = languages => {
  return languages.reduce((acc, cur) => {
    acc[cur] = null;
    return acc;
  }, {});
};

/**
 * @function getChallengeProducts
 * @description Format products to be indegrated in challenge form data.
 *
 * @param {Array} products
 * @param {Array.<string>} languages
 * @param {String} defaultLanguage
 * @param {Boolean} duplicating
 *
 * @returns {Array.Object}
 */
export const getChallengeProducts = ({ products, languages, defaultLanguage, duplicating }) => {
  const formattedProducts = products.map(product => {
    return {
      _id: !duplicating ? product._id || null : null,
      price: { value: product.price },
      currency: { value: product.currency },
      resourceType: { value: product.resourceType },
      identifier: { value: product.identifier },
      inventory: {
        type: { value: product.inventory.type },
        quantity: { value: product.inventory.quantity },
      },
      title: getInfosByLanguage({
        infos: product.display,
        languages,
        type: 'title',
        defaultLanguage,
      }),
      description: getInfosByLanguage({
        infos: product.display,
        languages,
        type: 'description',
        defaultLanguage,
      }),
      shortDescription: getInfosByLanguage({
        infos: product.display,
        languages,
        type: 'shortDescription',
        defaultLanguage,
      }),
      customerInformation: { value: product.display.customerInformation || [] },
      image: product.display.image || { url: null, file: null },
      connectedAccount: { value: product.connectedAccount },
      applicationFee: { value: product.applicationFee },
    };
  });

  return formattedProducts;
};

export const formattedFunctions = {
  countryValues,
  checkDisplayData,
  getChallengeCombinationsConditions,
  getChallengeDisplay,
  getChallengeEmails,
  getChallengeProducts,
  getInfosByLanguage,
  hasOnlyNumber,
  isSlugValid,
};
