var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from '../../utils';
import actions from './actions';
export function loadUserActivities(api, action) {
    var _a, userId, clientId, _b, skip, _c, limit, startAt, endAt, search, data, error_1;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = action.payload, userId = _a.userId, clientId = _a.clientId, _b = _a.skip, skip = _b === void 0 ? 0 : _b, _c = _a.limit, limit = _c === void 0 ? 20 : _c, startAt = _a.startAt, endAt = _a.endAt, search = _a.search;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .getActivities({
                        userId: userId,
                        clientId: clientId,
                        skip: skip,
                        limit: limit,
                        startAt: startAt,
                        endAt: endAt,
                        search: search
                    })
                        .then(function (p) { return p.request; })];
            case 2:
                data = _d.sent();
                return [4 /*yield*/, put(actions.loadUserActivitiesSuccess(data))];
            case 3:
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                error_1 = _d.sent();
                return [4 /*yield*/, put(actions.loadUserActivitiesError(error_1))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function replayUserActivity(api, action) {
    var _a, activityId, clientId, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, activityId = _a.activityId, clientId = _a.clientId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .replayActivity({ activityId: activityId, clientId: clientId })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.replayUserActivitySuccess({ activityId: activityId, clientId: clientId }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_2 = _b.sent();
                return [4 /*yield*/, put(actions.replayUserActivityError(error_2))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function createUserActivity(api, action) {
    var _a, activity, userId, clientId, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, activity = _a.activity, userId = _a.userId, clientId = _a.clientId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .createActivity({ activity: activity, userId: userId, clientId: clientId })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.createUserActivitySuccess())];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.createUserActivityError(error_3))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function editUserActivity(api, action) {
    var _a, activity, userId, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, activity = _a.activity, userId = _a.userId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .editActivity({ activity: activity, userId: userId })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.editUserActivitySuccess({ activityId: activity._id }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_4 = _b.sent();
                return [4 /*yield*/, put(actions.editUserActivityError(error_4))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function deleteUserActivity(api, action) {
    var _a, activityId, userId, clientId, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, activityId = _a.activityId, userId = _a.userId, clientId = _a.clientId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, api
                        .deleteActivity({ activityId: activityId, userId: userId, clientId: clientId })
                        .then(function (p) { return p.request; })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(actions.deleteUserActivitySuccess({ activityId: activityId }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                error_5 = _b.sent();
                return [4 /*yield*/, put(actions.deleteUserActivityError(error_5))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function watchLoadUserActivities(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadUserActivities), loadUserActivities, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchReplayUserActivity(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.replayUserActivity), replayUserActivity, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchCreateUserActivity(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.createUserActivity), createUserActivity, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchEditUserActivity(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.editUserActivity), editUserActivity, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchDeleteUserActivity(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.deleteUserActivity), deleteUserActivity, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userActivitiesSagas = function (api) { return [
    fork(watchLoadUserActivities, api),
    fork(watchReplayUserActivity, api),
    fork(watchCreateUserActivity, api),
    fork(watchEditUserActivity, api),
    fork(watchDeleteUserActivity, api),
]; };
export default userActivitiesSagas;
