import axios from 'axios';

import { UsersCoreApi } from '@sportheroes/oly-engine-api';

import storage from 'utils/storage';

import { olyEngineApi } from './Config/olyEngineApi';

const { CORE_API_URL } = process.env;

export default new UsersCoreApi(CORE_API_URL, { fromAdmin: true }, olyEngineApi);

export const loginAsUser = ({ userId, clientId }) => {
  return axios({
    baseURL: CORE_API_URL,
    url: `/v3/users/${userId}/login-to/${clientId}?clientId=${clientId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: storage.get('token'),
    },
  });
};
