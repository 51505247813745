import { UsersApi, Search } from '@sportheroes/oly-engine-api';

import { olyEngineApi } from './Config/olyEngineApi';
import { getDeviceId, getDeviceName } from './Config/utils';

const { OLY_ALGOLIA_APPLICATION_ID, OLY_ALGOLIA_API_KEY, USERS_API_URL } = process.env;
const AlgoliaSearchService = new Search(OLY_ALGOLIA_APPLICATION_ID, OLY_ALGOLIA_API_KEY);

export default new UsersApi(
  USERS_API_URL,
  { fromAdmin: true, deviceId: getDeviceId(), deviceName: getDeviceName() },
  olyEngineApi,
  AlgoliaSearchService,
);
