import {
  FETCH_CHALLENGES_PENDING,
  FETCH_CHALLENGES_SUCCESS,
  FETCH_CHALLENGES_ERROR,
  RESET_CHALLENGES,
  EDIT_CHALLENGE_STATE_SUCCESS,
  CREATE_CHALLENGE_ERROR,
  DELETE_CHALLENGE_SUCCESS,
  DELETE_CHALLENGE_ERROR,
  EDIT_CHALLENGE_CODES_ERROR,
  EDIT_CHALLENGE_PRIZES_ERROR,
  EDIT_CHALLENGE_BONUS_ERROR,
} from './constants';

const INITIAL_STATE = {
  data: null,
  hasMore: true,
  isLoading: false,
  error: null,
  filters: null,
  sorting: '-timeframe.start',
};

const formatedChallengestoUpdate = (action, state) => {
  const {
    payload: { challengeId, isHidden },
  } = action;
  const { data } = state;

  const challengeIndex = data.findIndex(item => item.general._id === challengeId);

  if (challengeIndex !== -1) {
    data[challengeIndex].general.isHidden.value = isHidden;
  }
  return [...data];
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CHALLENGES_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CHALLENGES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        hasMore: action.payload.hasMore,
        totalQuery: action.payload.totalQuery,
        isLoading: false,
        error: null,
      };

    case EDIT_CHALLENGE_STATE_SUCCESS:
      return {
        ...state,
        data:
          state.data && state.data.length > 0 ? formatedChallengestoUpdate(action, state) : null,
      };

    case RESET_CHALLENGES:
      return { ...INITIAL_STATE, isLoading: true };
    case DELETE_CHALLENGE_SUCCESS:
      return {
        ...state,
        data:
          state?.data?.filter(challenge => challenge.general._id !== action.payload.challengeId) ||
          [],
        isLoading: false,
      };

    case CREATE_CHALLENGE_ERROR:
    case FETCH_CHALLENGES_ERROR:
    case DELETE_CHALLENGE_ERROR:
    case EDIT_CHALLENGE_CODES_ERROR:
    case EDIT_CHALLENGE_PRIZES_ERROR:
    case EDIT_CHALLENGE_BONUS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
