import orderBy from 'lodash/orderBy';

import {
  FETCH_CLIENTS_PENDING,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_ERROR,
  FETCH_CURRENT_CLIENT_SUCCESS,
  FETCH_CURRENT_CLIENT_ERROR,
  RESET_CLIENTS,
  RESET_CURRENT_CLIENT,
} from './constants';

const INITIAL_STATE = {
  active: null,
  inactive: null,
  list: {
    data: null,
    isLoading: false,
    error: null,
  },
  currentClient: null,
  currentClientError: null,
};

const formatedOtherClients = action => {
  const { clients } = action.payload;

  const currentDate = new Date();

  const defaultOtherClients = {
    active: [],
    inactive: [],
  };

  const otherClients = clients?.reduce(
    (result, client) => {
      const { period } = client;

      let key = 'active';

      if (period && period.endDate) {
        const endDate = new Date(period.endDate);

        if (currentDate < endDate) {
          // Active client
          key = 'active';
        } else {
          // Inactive client
          key = 'inactive';
        }
      }

      result[key] = orderBy([client, ...result[key]], 'name');

      return result;
    },
    {
      active: [],
      inactive: [],
    },
  );

  return otherClients || defaultOtherClients;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENT_CLIENT_SUCCESS:
      return {
        ...state,
        currentClient: action.payload.currentClient,
      };

    case FETCH_CURRENT_CLIENT_ERROR:
      return {
        ...state,
        currentClientError: action.payload.error,
      };

    case FETCH_CLIENTS_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: true,
        },
      };

    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        ...formatedOtherClients(action),
        list: {
          ...state.list,
          isLoading: false,
          data: action.payload.clients,
        },
      };

    case FETCH_CLIENTS_ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          error: action.payload.error,
        },
      };

    case RESET_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: null,
        currentClientError: null,
      };

    case RESET_CLIENTS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
