import { createSelector } from 'reselect';
import get from 'lodash/get';

const usersSelector = state => get(state, 'users');

export const usersDataSelector = createSelector(usersSelector, users => get(users, 'data'));

export const usersIsLoadingSelector = createSelector(usersSelector, users =>
  get(users, 'isLoading'),
);

export const usersErrorSelector = createSelector(usersSelector, users => get(users, 'error'));

export const usersHasMoreSelector = createSelector(usersSelector, users => get(users, 'hasMore'));
