var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_CURRENT_SUBSECTION_ID, SET_SECTIONS_COLLAPSED, SET_COLLAPSE_SECTIONS_LANGS, } from './constants';
var INITIAL_STATE = {
    currentSubSectionId: null,
    sectionsCollapsed: [],
    collapsedSectionsLangs: null
};
var reducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case SET_CURRENT_SUBSECTION_ID:
            return __assign(__assign({}, state), { currentSubSectionId: action.payload });
        case SET_SECTIONS_COLLAPSED:
            return __assign(__assign({}, state), { sectionsCollapsed: action.payload });
        case SET_COLLAPSE_SECTIONS_LANGS:
            return __assign(__assign({}, state), { collapsedSectionsLangs: action.payload });
        default:
            return state;
    }
};
export default reducer;
