import { createSelector } from 'reselect';
import get from 'lodash/get';

const notificationsSelector = state => get(state, 'notifications');

export const notificationsDataSelector = createSelector(notificationsSelector, notifications =>
  get(notifications, 'data'),
);

export const fistNotificationIdSelector = createSelector(notificationsDataSelector, data =>
  data && data.length > 0 ? data[0].id : null,
);
