var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var initialState = {
    loadUserApps: {
        data: null,
        isLoading: false,
        error: null
    },
    pullUserApps: {
        isLoading: false,
        success: null,
        error: null
    },
    deleteUserApp: {
        deletedAppName: null,
        isLoading: false,
        error: null
    }
};
var appsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'OLY_LOAD_USER_APPS':
            return __assign(__assign({}, state), { loadUserApps: __assign(__assign({}, initialState.loadUserApps), { isLoading: true }) });
        case 'OLY_LOAD_USER_APPS_SUCCESS':
            return __assign(__assign({}, state), { loadUserApps: __assign(__assign({}, initialState.loadUserApps), { data: action.payload.loadUserApps }) });
        case 'OLY_LOAD_USER_APPS_ERROR':
            return __assign(__assign({}, state), { loadUserApps: __assign(__assign({}, initialState.loadUserApps), { error: action.payload.error }) });
        case 'OLY_PULL_USER_APPS':
            return __assign(__assign({}, state), { pullUserApps: __assign(__assign({}, initialState.pullUserApps), { isLoading: true }) });
        case 'OLY_PULL_USER_APPS_SUCCESS':
            return __assign(__assign({}, state), { pullUserApps: __assign(__assign({}, initialState.pullUserApps), { success: true }) });
        case 'OLY_PULL_USER_APPS_ERROR':
            return __assign(__assign({}, state), { pullUserApps: __assign(__assign({}, initialState.pullUserApps), { error: action.payload.error }) });
        case 'OLY_DELETE_USER_APP':
            return __assign(__assign({}, state), { deleteUserApp: __assign(__assign({}, initialState.deleteUserApp), { isLoading: true }) });
        case 'OLY_DELETE_USER_APP_SUCCESS':
            return __assign(__assign({}, state), { deleteUserApp: __assign(__assign({}, initialState.deleteUserApp), { deletedAppName: action.payload.deletedAppName }) });
        case 'OLY_DELETE_USER_APP_ERROR':
            return __assign(__assign({}, state), { deleteUserApp: __assign(__assign({}, initialState.deleteUserApp), { error: action.payload.error }) });
        case 'OLY_USER_APP_RESET':
            return initialState;
        default:
            return state;
    }
};
export default appsReducer;
