export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';
export const RESET_ROLES = 'RESET_ROLES';

export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_ERROR = 'EDIT_ROLE_ERROR';
