import { createSelector } from 'reselect';
import get from 'lodash/get';
var userStateSelector = function (state) { return state.user; };
export var userChallengesStateSelector = createSelector(userStateSelector, function (user) { return user.challenges; });
export var userRegisteredChallengesSelector = createSelector(userChallengesStateSelector, function (challenges) { return challenges.userRegisteredChallenges; });
export var userRegisteredChallengesDataSelector = createSelector(userRegisteredChallengesSelector, function (userRegisteredChallenges) {
    return userRegisteredChallenges ? userRegisteredChallenges.data : null;
});
var userRegisteredChallengesMetaSelector = createSelector(userRegisteredChallengesSelector, function (userRegisteredChallenges) {
    return userRegisteredChallenges ? userRegisteredChallenges.meta : null;
});
var userRegisteredChallengesHasNextSelector = createSelector(userRegisteredChallengesMetaSelector, function (userRegisteredChallengesMeta) {
    return userRegisteredChallengesMeta ? userRegisteredChallengesMeta.hasNext : null;
});
export var userRegisteredChallengesErrorSelector = createSelector(userRegisteredChallengesSelector, function (userRegisteredChallenges) {
    return userRegisteredChallenges ? userRegisteredChallenges.error : null;
});
// userGroupChallengeContribution
var getUserGroupChallengeContribution = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'userGroupChallengeContribution');
});
export var getUserGroupChallengeContributionDataSelector = createSelector(getUserGroupChallengeContribution, function (userGroupChallengeContribution) { return get(userGroupChallengeContribution, 'data'); });
export var getUserGroupChallengeContributionLoadingSelector = createSelector(getUserGroupChallengeContribution, function (userGroupChallengeContribution) { return get(userGroupChallengeContribution, 'isLoading'); });
// challengeRegister
var getUserRegistration = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'registration');
});
export var getUserRegistrationSuccessSelector = createSelector(getUserRegistration, function (userRegistration) { return get(userRegistration, 'success'); });
export var getUserRegistrationErrorSelector = createSelector(getUserRegistration, function (userRegistration) { return get(userRegistration, 'error'); });
export var getUserRegistrationLoadingSelector = createSelector(getUserRegistration, function (userRegistration) { return get(userRegistration, 'isLoading'); });
// get user challenge
var getUserChallengeStateSelector = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'userChallenge');
});
export var getUserChallengeDataSelector = createSelector(getUserChallengeStateSelector, function (userChallenge) { return get(userChallenge, 'data'); });
// update user challenge
export var getUpdateUserChallengeSuccess = createSelector(userChallengesStateSelector, function (challenge) { return get(challenge, 'hasUpdatedUserChallenge'); });
// user challenge activities
var getUserChallengeActivities = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'userChallengeActivities');
});
export var getUserChallengeActivitiesDataSelector = createSelector(getUserChallengeActivities, function (userChallengeActivities) { return get(userChallengeActivities, 'data'); });
export var getUserChallengeActivitiesLoadingSelector = createSelector(getUserChallengeActivities, function (userChallengeActivities) { return get(userChallengeActivities, 'isLoading'); });
// user challenge activities around
var getUserChallengeActivitiesAround = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'userChallengeActivitiesAround');
});
export var getUserChallengeActivitiesAroundDataSelector = createSelector(getUserChallengeActivitiesAround, function (userChallengeActivitiesAround) { return get(userChallengeActivitiesAround, 'data'); });
// user challenge attempts
var getUserChallengeAttempts = createSelector(userChallengesStateSelector, function (challenge) {
    return get(challenge, 'userChallengeAttempts');
});
export var getUserChallengeAttemptsDataSelector = createSelector(getUserChallengeAttempts, function (userChallengeAttempts) { return get(userChallengeAttempts, 'data'); });
export var getUserChallengeActivitiesAroundLoadingSelector = createSelector(getUserChallengeActivitiesAround, function (userChallengeActivitiesAround) { return get(userChallengeActivitiesAround, 'isLoading'); });
export var getUserGroupChallengeSelector = createSelector(userChallengesStateSelector, function (challenge) { return get(challenge, 'userGroupChallenge'); });
export var getUserGroupChallengeDataSelector = createSelector(getUserGroupChallengeSelector, function (userGroup) { return get(userGroup, 'data'); });
export var getIsUserGroupChallengeLoadingSelector = createSelector(getUserGroupChallengeSelector, function (userGroup) { return get(userGroup, 'isLoading'); });
export var getUserGroupChallengeRequestResult = createSelector(getUserGroupChallengeSelector, function (userGroup) { return get(userGroup, 'requestResult'); });
export default {
    getUpdateUserChallengeSuccess: getUpdateUserChallengeSuccess,
    getUserChallengeActivitiesAroundDataSelector: getUserChallengeActivitiesAroundDataSelector,
    getUserChallengeActivitiesAroundLoadingSelector: getUserChallengeActivitiesAroundLoadingSelector,
    getUserChallengeActivitiesDataSelector: getUserChallengeActivitiesDataSelector,
    getUserChallengeActivitiesLoadingSelector: getUserChallengeActivitiesLoadingSelector,
    getUserChallengeAttemptsDataSelector: getUserChallengeAttemptsDataSelector,
    getUserChallengeDataSelector: getUserChallengeDataSelector,
    getUserGroupChallengeContributionDataSelector: getUserGroupChallengeContributionDataSelector,
    getUserGroupChallengeContributionLoadingSelector: getUserGroupChallengeContributionLoadingSelector,
    getUserRegistrationErrorSelector: getUserRegistrationErrorSelector,
    getUserRegistrationLoadingSelector: getUserRegistrationLoadingSelector,
    getUserRegistrationSuccessSelector: getUserRegistrationSuccessSelector,
    getUserGroupChallengeSelector: getUserGroupChallengeSelector,
    userRegisteredChallengesDataSelector: userRegisteredChallengesDataSelector,
    userRegisteredChallengesHasNextSelector: userRegisteredChallengesHasNextSelector,
    userRegisteredChallengesMetaSelector: userRegisteredChallengesMetaSelector,
    userRegisteredChallengesSelector: userRegisteredChallengesSelector,
    getUserGroupChallengeDataSelector: getUserGroupChallengeDataSelector,
    getIsUserGroupChallengeLoadingSelector: getIsUserGroupChallengeLoadingSelector,
    getUserGroupChallengeRequestResult: getUserGroupChallengeRequestResult
};
