import { combineReducers } from 'redux';

import {
  activitiesReducer,
  challengesReducer,
  couponsReducer,
  rankingsReducer,
  rewardsReducer,
  collectionsReducer,
  blogReducer,
  segmentsReducer,
} from '@sportheroes/oly-engine-store';

import authReducer from './auth/reducers';
import clientsReducer from './clients/reducers';
import challengesReducerLegacy from './challenges/reducers';
import formReducer from './form/reducers';
import groupReducer from './group/reducers';
import groupsReducer from './groups/reducers';
import modalReducer from './modal/reducers';
import notificationsReducer from './notifications/reducers';
import rolesReducer from './roles/reducers';
import usersSearchReducer from './usersSearch/reducers';
import usersReducer from './users/reducers';
import userReducer from './user/reducers';

export default combineReducers({
  auth: authReducer,
  clients: clientsReducer,
  challengesLegacy: challengesReducerLegacy,
  form: formReducer,
  groups: groupsReducer,
  group: groupReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  roles: rolesReducer,
  usersSearch: usersSearchReducer,
  users: usersReducer,
  collections: collectionsReducer,
  activities: activitiesReducer,
  challenges: challengesReducer,
  coupons: couponsReducer,
  rankings: rankingsReducer,
  user: userReducer,
  rewards: rewardsReducer,
  blog: blogReducer,
  segments: segmentsReducer,
});
