var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { combineReducers } from 'redux';
// LOAD USER ACTIVITIES
export var loadUserActivitiesState = {
    data: null,
    metadata: null,
    isLoading: false,
    error: null
};
var loadUserActivitiesReducer = function (state, action) {
    if (state === void 0) { state = loadUserActivitiesState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES':
            return __assign(__assign({}, state), { isLoading: true, error: null });
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_SUCCESS':
            return __assign(__assign({}, state), { isLoading: false, data: action.payload.metadata.skip > 0 && state.data
                    ? __spreadArray(__spreadArray([], state.data, true), action.payload.data, true) : action.payload.data, metadata: action.payload.metadata });
        case 'OLY_ENGINE_STORE_LOAD_USER_ACTIVITIES_ERROR':
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case 'OLY_ENGINE_STORE_RESET_USER_ACTIVITIES':
            return loadUserActivitiesState;
        default:
            return state;
    }
};
// REPLAY USER ACTIVITY
export var replayUserActivityState = {
    replayedActivityId: null,
    isLoading: false,
    error: null
};
var replayUserActivityReducer = function (state, action) {
    if (state === void 0) { state = replayUserActivityState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY':
            return { replayedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_SUCCESS':
            return {
                replayedActivityId: action.payload.activityId,
                isLoading: false,
                error: null
            };
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_ERROR':
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case 'OLY_ENGINE_STORE_REPLAY_USER_ACTIVITY_RESET':
            return replayUserActivityState;
        default:
            return state;
    }
};
// CREATE USER ACTIVITY
export var createUserActivityState = {
    createdActivity: null,
    isLoading: false,
    error: null
};
var createUserActivityReducer = function (state, action) {
    if (state === void 0) { state = createUserActivityState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY':
            return { createdActivity: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_SUCCESS':
            return {
                createdActivity: true,
                isLoading: false,
                error: null
            };
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_ERROR':
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case 'OLY_ENGINE_STORE_CREATE_USER_ACTIVITY_RESET':
            return createUserActivityState;
        default:
            return state;
    }
};
// EDIT USER ACTIVITY
export var editUserActivityState = {
    editedActivityId: null,
    isLoading: false,
    error: null
};
var editUserActivityReducer = function (state, action) {
    if (state === void 0) { state = editUserActivityState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY':
            return { editedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_SUCCESS':
            return {
                editedActivityId: action.payload.activityId,
                isLoading: false,
                error: null
            };
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_ERROR':
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case 'OLY_ENGINE_STORE_EDIT_USER_ACTIVITY_RESET':
            return editUserActivityState;
        default:
            return state;
    }
};
// DELETE USER ACTIVITY
export var deleteUserActivityState = {
    deletedActivityId: null,
    isLoading: false,
    error: null
};
var deleteUserActivityReducer = function (state, action) {
    if (state === void 0) { state = deleteUserActivityState; }
    switch (action.type) {
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY':
            return { deletedActivityId: null, isLoading: true, error: null };
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_SUCCESS':
            return {
                deletedActivityId: action.payload.activityId,
                isLoading: false,
                error: null
            };
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_ERROR':
            return __assign(__assign({}, state), { isLoading: false, error: action.payload.error });
        case 'OLY_ENGINE_STORE_DELETE_USER_ACTIVITY_RESET':
            return deleteUserActivityState;
        default:
            return state;
    }
};
export var userActivitiesState = {
    loadUserActivities: loadUserActivitiesState,
    replayUserActivity: replayUserActivityState,
    createUserActivity: createUserActivityState,
    editUserActivity: editUserActivityState,
    deleteUserActivity: deleteUserActivityState
};
export default combineReducers({
    loadUserActivities: loadUserActivitiesReducer,
    replayUserActivity: replayUserActivityReducer,
    createUserActivity: createUserActivityReducer,
    editUserActivity: editUserActivityReducer,
    deleteUserActivity: deleteUserActivityReducer
});
