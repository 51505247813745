import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border: 2px solid ${p => p.theme.light[p.status].borderColor};
  background-color: ${p => p.theme.light[p.status].bgColor};
  border-radius: 50%;
  display: inline-block;
`;

const RoundedLight = props => {
  const { status, customStyle } = props;
  return <Container title={status} style={customStyle} status={status} />;
};

RoundedLight.propTypes = {
  status: PropTypes.oneOf(['online', 'offline', 'scheduled']),
};

RoundedLight.defaultProps = {
  status: [],
};

export default RoundedLight;
