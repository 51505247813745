import { createSelector } from 'reselect';
import get from 'lodash/get';

import { PERMISSIONS } from '@sportheroes/sonic-constants';

const authSelector = state => get(state, 'auth');

export const authIsAuthenticatedSelector = createSelector(authSelector, auth =>
  get(auth, 'isAuthenticated'),
);

export const authUserSelector = createSelector(authSelector, auth => get(auth, 'user'));

export const rolesUserSelector = createSelector(authSelector, auth => get(auth, 'roles'));

export const availableClientsUserSelector = createSelector(authSelector, auth =>
  get(auth, 'availableClients'),
);

export const isUserGlobalAdminSelector = createSelector(authSelector, auth => {
  const authRoles = get(auth, 'roles', []);

  return authRoles.some(userRole => userRole.permissions[PERMISSIONS.ADMIN_GLOBAL]);
});

export const clientIdsUserRoleSelector = createSelector(authSelector, auth => {
  const authRoles = get(auth, 'roles', []);

  const isAdmin = authRoles.some(userRole => userRole.permissions[PERMISSIONS.ADMIN_GLOBAL]);

  if (isAdmin) {
    return null;
  }

  return authRoles.map(authRole => {
    const { contextId } = authRole;

    return contextId;
  });
});
