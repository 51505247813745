import {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  RESET_ROLES,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_ERROR,
} from './constants';

const INITIAL_STATE = {
  data: null,
  meta: null,
  isLoading: false,
  error: null,
  reload: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        isLoading: true,
        error: null,
        reload: false,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: false,
        error: null,
        reload: false,
      };
    case CREATE_ROLE_SUCCESS:
    case DELETE_ROLE_SUCCESS:
    case EDIT_ROLE_SUCCESS:
      return {
        ...state,
        error: null,
        reload: true,
      };
    case RESET_ROLES:
      return INITIAL_STATE;
    case CREATE_ROLE_ERROR:
    case DELETE_ROLE_ERROR:
    case EDIT_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        reload: true,
      };
    case FETCH_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        reload: false,
      };

    default:
      return state;
  }
};

export default reducer;
