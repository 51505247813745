import { initReactI18next } from 'react-i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHR from 'i18next-xhr-backend';
import i18n from 'i18next';
import axios from 'axios';

import storage from 'utils/storage';

import { DEFAULT_LANGUAGE } from '../constants';

const { OLY_I18N_SERVER } = process.env;

const INITIAL_PATH = `${OLY_I18N_SERVER}/merge/{{lng}}/translations.json`;

const PROJECT_IDS = [
  172782, // Zeus Admin
  140481, // Design-System
  145720, // Olympus App
];

export function loadI18n() {
  const language = storage.get('language') || DEFAULT_LANGUAGE;

  const loadPath = PROJECT_IDS.reduce((result, value, i) => {
    result += `${i === 0 ? '?' : '&'}${value}={{ns}}.json`;

    return result;
  }, INITIAL_PATH);

  return new Promise(resolve => {
    i18n
      .use(XHR)
      .use(initReactI18next)
      .on('loaded', () => resolve(i18n))
      .init({
        backend: {
          loadPath,
          backends: [LocalStorageBackend],
        },
        fallbackLng: [language],
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
          escapeValue: false,
        },
        nonExplicitWhitelist: true,
        react: {
          wait: true,
        },
      });
  });
}

export const loadLanguagesList = async () => {
  const response = await axios({
    baseURL: OLY_I18N_SERVER,
    url: `/merge/en/translations.json?172782=translations.json`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const {
    data: { 'zeus-common': { language } = {} },
  } = response;

  return language;
};

export const isKeyInTraductions = key => {
  return i18n.exists(key);
};

export default i18n;
