import * as Sentry from '@sentry/browser';

import UsersService from 'services/Users';
import UsersCoreService from 'services/UsersCore';
import { clientsCurrentIdSelector } from 'redux/clients/selectors';

import {
  FETCH_USERS_PENDING,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  RESET_USERS,
} from './constants';

const DEFAULT_LIMIT = 20;

export const fetchUsers = ({ skip = null, fetchMore = false }) => async (dispatch, getState) => {
  dispatch({ type: FETCH_USERS_PENDING });
  try {
    const usersLength = (getState().users.data || []).length;
    const currentClientId = clientsCurrentIdSelector(getState());

    const { results: data, metaData } = await UsersCoreService.getUsers({
      clientId: currentClientId,
      skip: skip || (fetchMore ? usersLength : 0),
      limit: DEFAULT_LIMIT,
      search: '',
    }).then(p => p.request);

    const newData = fetchMore ? [...(getState().users.data || []), ...data] : data;

    dispatch({
      type: FETCH_USERS_SUCCESS,
      payload: { data: newData, hasMore: metaData.total > newData.length },
    });
  } catch (error) {
    Sentry.captureException(error);
    dispatch({ type: FETCH_USERS_ERROR, payload: { error } });
  }
};

export const resetUsers = () => ({
  type: RESET_USERS,
});

// return data without dispatch
export const fetchUser = async (payload, options = {}) => {
  const { userId, clientId } = payload;
  const { withUser = true } = options;

  try {
    const promises = [
      withUser && UsersService.getUser({ id: userId, clientId }).then(p => p.request),
    ];

    const [user] = await Promise.all(promises);

    return {
      data: {
        ...(withUser && { user }),
      },
      error: null,
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      data: null,
      error: {
        code: 500,
        message: 'global_error',
      },
    };
  }
};
