import React, { useEffect, useState, useCallback } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n, { loadI18n } from 'utils/i18n';

import App from './App';

const Root = props => {
  const { store } = props;

  const [loading, setLoading] = useState(true);

  const initI18n = useCallback(async () => {
    await loadI18n();

    setLoading(false);
  }, []);

  useEffect(() => {
    initI18n();
  }, [initI18n]);

  return (
    !loading && (
      <I18nextProvider i18n={i18n}>
        <App store={store} loading={loading} />
      </I18nextProvider>
    )
  );
};

export default Root;
