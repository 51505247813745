import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

import rootReducer from './reducers';
import sagas from './sagas';

const { NODE_ENV } = process.env;

const sagaMiddleware = createSagaMiddleware();
const sentryMiddleware = createSentryMiddleware(Sentry, {});

export default function configureStore(initialState = {}) {
  const middlewares = [thunk, sagaMiddleware, sentryMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    NODE_ENV === 'development' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

  sagaMiddleware.run(sagas);

  store.runSaga = sagaMiddleware.run;

  if (module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}
