var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fork, put, takeLatest } from 'redux-saga/effects';
import { getType } from '../../utils';
import actions from './actions';
export function loadSelectedUserClients(api, action) {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, api
                        .getUserClients({
                        id: action.payload.userId,
                        clientId: action.payload.clientId
                    })
                        .then(function (p) { return p.request; })];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(actions.loadUserClientsSuccess(data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_1 = _a.sent();
                return [4 /*yield*/, put(actions.loadUserClientsError(true))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function loadOneUserClient(api, action) {
    var _a, userId, clientId, data, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                _a = action.payload || {}, userId = _a.userId, clientId = _a.clientId;
                return [4 /*yield*/, api
                        .getOneUserClient({
                        id: userId,
                        clientId: clientId
                    })
                        .then(function (p) { return p.request; })];
            case 1:
                data = _b.sent();
                return [4 /*yield*/, put(actions.loadOneUserClientSuccess(data))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                error_2 = _b.sent();
                return [4 /*yield*/, put(actions.loadOneUserClientError(true))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function updateUserClientSettings(api, action) {
    var _a, userId, clientId, settings, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                _a = action.payload || {}, userId = _a.userId, clientId = _a.clientId, settings = _a.settings;
                return [4 /*yield*/, api
                        .editUserSettings({
                        id: userId,
                        clientId: clientId,
                        settings: settings
                    })
                        .then(function (p) { return p.request; })];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(actions.updateUserClientSettingsSuccess(settings))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.updateUserClientSettingsError(true))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function watchUserClients(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadSelectedUserClients), loadSelectedUserClients, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchUserOneClient(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.loadOneUserClient), loadOneUserClient, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function watchUpdateUserClientSettings(api) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(getType(actions.updateUserClientSettings), updateUserClientSettings, api)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export var userClientsSagas = function (api) { return [
    fork(watchUserClients, api),
    fork(watchUserOneClient, api),
    fork(watchUpdateUserClientSettings, api),
]; };
export default userClientsSagas;
