import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
var userInfoStateSelector = createSelector(userStateSelector, function (user) { return user.info; });
var loadUserInfoStateSelector = createSelector(userInfoStateSelector, function (info) { return info.loadUserInfo; });
export var loadUserInfoSelector = createSelector(loadUserInfoStateSelector, function (info) { return info.userInfo; });
export var loadUserInfoIsLoadingSelector = createSelector(loadUserInfoStateSelector, function (info) { return info.isLoading; });
export var loadUserInfoErrorSelector = createSelector(loadUserInfoStateSelector, function (info) { return info.error; });
var editUserInfoStateSelector = createSelector(userInfoStateSelector, function (info) { return info.editUserInfo; });
export var editedUserInfoIdSelector = createSelector(editUserInfoStateSelector, function (editUserInfo) { return editUserInfo.editedUserId; });
export var editUserInfoIsLoadingSelector = createSelector(editUserInfoStateSelector, function (editUserInfo) { return editUserInfo.isLoading; });
export var editUserInfoErrorSelector = createSelector(editUserInfoStateSelector, function (editUserInfo) { return editUserInfo.error; });
var editUserClientStateSelector = createSelector(userInfoStateSelector, function (info) { return info.editUserClient; });
export var editUserClientSuccessSelector = createSelector(editUserClientStateSelector, function (editUserClient) { return editUserClient.success; });
export var editUserClientIsLoadingSelector = createSelector(editUserClientStateSelector, function (editUserClient) { return editUserClient.isLoading; });
export var editUserClientErrorSelector = createSelector(editUserClientStateSelector, function (editUserClient) { return editUserClient.error; });
export var loadUserInfoGroupSelector = createSelector(loadUserInfoSelector, function (userInfo) {
    return userInfo ? userInfo.groups && userInfo.groups[0] : null;
});
var deleteUserInfoGroupStateSelector = createSelector(userInfoStateSelector, function (info) { return info.deleteUserInfoGroup; });
export var deletedUserInfoGroupIdSelector = createSelector(deleteUserInfoGroupStateSelector, function (deleteUserInfoGroup) { return deleteUserInfoGroup.deletedUserGroupId; });
export var deleteUserInfoGroupErrorSelector = createSelector(deleteUserInfoGroupStateSelector, function (deleteUserInfoGroup) { return deleteUserInfoGroup.error; });
export var deleteUserInfoGroupIsLoadingSelector = createSelector(deleteUserInfoGroupStateSelector, function (deleteUserInfoGroup) { return deleteUserInfoGroup.isLoading; });
var deleteUserInfoClientStateSelector = createSelector(userInfoStateSelector, function (info) { return info.deleteUserInfoClient; });
export var deletedUserInfoClientIdSelector = createSelector(deleteUserInfoClientStateSelector, function (deleteUserInfoClient) { return deleteUserInfoClient.deletedUserClientId; });
export var deleteUserInfoClientErrorSelector = createSelector(deleteUserInfoClientStateSelector, function (deleteUserInfoClient) { return deleteUserInfoClient.error; });
export var deleteUserInfoClientIsLoadingSelector = createSelector(deleteUserInfoClientStateSelector, function (deleteUserInfoClient) { return deleteUserInfoClient.isLoading; });
var deleteUserStateSelector = createSelector(userInfoStateSelector, function (info) { return info.deleteUser; });
export var deletedUserIdSelector = createSelector(deleteUserStateSelector, function (deleteUser) { return deleteUser.deletedUserId; });
export var deleteUserErrorSelector = createSelector(deleteUserStateSelector, function (deleteUser) { return deleteUser.error; });
export var deleteUserIsLoadingSelector = createSelector(deleteUserStateSelector, function (deleteUser) { return deleteUser.isLoading; });
export default {
    loadUserInfoSelector: loadUserInfoSelector,
    loadUserInfoErrorSelector: loadUserInfoErrorSelector,
    loadUserInfoIsLoadingSelector: loadUserInfoIsLoadingSelector,
    loadUserInfoGroupSelector: loadUserInfoGroupSelector,
    editedUserInfoIdSelector: editedUserInfoIdSelector,
    editUserInfoErrorSelector: editUserInfoErrorSelector,
    editUserInfoIsLoadingSelector: editUserInfoIsLoadingSelector,
    editUserClientSuccessSelector: editUserClientSuccessSelector,
    editUserClientErrorSelector: editUserClientErrorSelector,
    editUserClientIsLoadingSelector: editUserClientIsLoadingSelector,
    deletedUserInfoGroupIdSelector: deletedUserInfoGroupIdSelector,
    deleteUserInfoGroupErrorSelector: deleteUserInfoGroupErrorSelector,
    deleteUserInfoGroupIsLoadingSelector: deleteUserInfoGroupIsLoadingSelector,
    deletedUserInfoClientIdSelector: deletedUserInfoClientIdSelector,
    deleteUserInfoClientErrorSelector: deleteUserInfoClientErrorSelector,
    deleteUserInfoClientIsLoadingSelector: deleteUserInfoClientIsLoadingSelector,
    deletedUserIdSelector: deletedUserIdSelector,
    deleteUserErrorSelector: deleteUserErrorSelector,
    deleteUserIsLoadingSelector: deleteUserIsLoadingSelector
};
