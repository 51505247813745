// LOAD USER APPS
export var loadUserApps = function (payload) {
    return ({
        type: 'OLY_LOAD_USER_APPS',
        payload: payload
    });
};
export var loadUserAppsSuccess = function (loadUserApps) {
    return ({
        type: 'OLY_LOAD_USER_APPS_SUCCESS',
        payload: { loadUserApps: loadUserApps }
    });
};
export var loadUserAppsError = function (error) {
    return ({
        type: 'OLY_LOAD_USER_APPS_ERROR',
        payload: { error: error }
    });
};
// PULL USER APPS
export var pullUserApps = function (payload) {
    return ({
        type: 'OLY_PULL_USER_APPS',
        payload: payload
    });
};
export var pullUserAppsSuccess = function () {
    return ({
        type: 'OLY_PULL_USER_APPS_SUCCESS'
    });
};
export var pullUserAppsError = function (error) {
    return ({
        type: 'OLY_PULL_USER_APPS_ERROR',
        payload: { error: error }
    });
};
// DELETE USER APP
export var deleteUserApp = function (payload) {
    return ({
        type: 'OLY_DELETE_USER_APP',
        payload: payload
    });
};
export var deleteUserAppSuccess = function (deletedAppName) {
    return ({
        type: 'OLY_DELETE_USER_APP_SUCCESS',
        payload: { deletedAppName: deletedAppName }
    });
};
export var deleteUserAppError = function (error) {
    return ({
        type: 'OLY_DELETE_USER_APP_ERROR',
        payload: { error: error }
    });
};
// USER APP RESET
export var userAppsReset = function () {
    return ({
        type: 'OLY_USER_APP_RESET'
    });
};
export default {
    loadUserApps: loadUserApps,
    loadUserAppsSuccess: loadUserAppsSuccess,
    loadUserAppsError: loadUserAppsError,
    pullUserApps: pullUserApps,
    pullUserAppsSuccess: pullUserAppsSuccess,
    pullUserAppsError: pullUserAppsError,
    deleteUserApp: deleteUserApp,
    deleteUserAppSuccess: deleteUserAppSuccess,
    deleteUserAppError: deleteUserAppError,
    userAppsReset: userAppsReset
};
