import get from 'lodash/get';

// Return the first error if data field does not pass his validation rules
export const getFieldError = (validationsField, dataField) => {
  const errors = Object.keys(validationsField).map(key => {
    return validationsField[key].rule(dataField) ? null : validationsField[key].error;
  });
  return errors.find(error => error);
};

export const getDependencyError = (field, dependantField) => {
  return `zeus-common.field.validation.${field}.dependant.from.${dependantField}`;
};

export const getInvalidFields = (data, fields) => {
  return Object.keys(fields).reduce((acc, field) => {
    const validationsField = fields[field];
    const dataField = get(data, field);

    const error = getFieldError(validationsField, dataField);

    return error ? { ...acc, [field]: error } : acc;
  }, {});
};

export const getInvalidDependencyFields = (data, validationDependencies) => {
  return Object.keys(validationDependencies).reduce((acc, field) => {
    const dataField = get(data, field);
    const dependantDataField = get(data, validationDependencies[field]);

    if (dataField && !dependantDataField) {
      return {
        ...acc,
        [validationDependencies[field]]: getDependencyError(field, validationDependencies[field]),
      };
    }

    return acc;
  }, {});
};

// Common Field validations
export const isRequired = {
  rule: value => !!value,
  error: 'zeus-common.field.validation.required',
};

export const minLength = {
  rule: value => value && value.length && value.length >= 2,
  error: 'zeus-common.field.validation.minlength',
};

export const maxLength = {
  rule: value => value && value.length && value.length <= 200,
  error: `zeus-common.field.validation.maxlength`,
};

export const hasOnlyNumber = value => {
  const hasOnlyNumber = /^[0-9]+([.][0-9]+)*$/;
  return value && value.length && hasOnlyNumber.test(value);
};

export const isSlugValid = value => {
  const isSlugValid = /^[a-z0-9-]+$/;
  return value && value.length && isSlugValid.test(value);
};

export const isHexColor = value => {
  const isHex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
  return value && value.length && isHex.test(value);
};

export const isNameValid = value => {
  const isNameValid = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
  return isNameValid.test(value);
};

export const isEmailValid = value => {
  const isEmailValid = /^[\w-]+(\.[\w+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/;
  return isEmailValid.test(value);
};

export const isUrlValid = value => {
  const isUrlValid = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return isUrlValid.test(value);
};

export const isUrlHttpsValid = value => {
  const isUrlValid = /(https:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  return isUrlValid.test(value);
};

export const isFormDataEqual = (initialData, data) => {
  return JSON.stringify(initialData) === JSON.stringify(data);
};

export const isUrl = {
  rule: value => isUrlValid(value),
  error: 'zeus-common.field.validation.url',
};

export const isUrlHttps = {
  rule: value => isUrlHttpsValid(value),
  error: 'zeus-common.field.validation.url',
};

export const isJsonValid = value => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const jsonPrettier = json => {
  return JSON.stringify(JSON.parse(json), null, 2);
};

export const mergeFormLangs = ({ formLangs, newLangs }) => {
  const concatLangs = Array.from(new Set([...formLangs, ...newLangs]));
  return concatLangs;
};
