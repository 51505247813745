import { createSelector } from 'reselect';
var userStateSelector = function (state) { return state.user; };
export var userCoreStateSelector = createSelector(userStateSelector, function (user) { return user.core; });
export var uploadProfilePictureSelector = createSelector(userCoreStateSelector, function (core) { return core.uploadProfilePicture; });
export var profilePictureHasBeenUploadedSelector = createSelector(uploadProfilePictureSelector, function (uploadProfilePicture) { return uploadProfilePicture.hasBeenUploaded; });
export var uploadProfilePictureErrorSelector = createSelector(uploadProfilePictureSelector, function (uploadProfilePicture) { return uploadProfilePicture.error; });
export var uploadProfilePictureIsLoadingSelector = createSelector(uploadProfilePictureSelector, function (uploadProfilePicture) { return uploadProfilePicture.isLoading; });
export default {
    profilePictureHasBeenUploadedSelector: profilePictureHasBeenUploadedSelector,
    uploadProfilePictureErrorSelector: uploadProfilePictureErrorSelector,
    uploadProfilePictureIsLoadingSelector: uploadProfilePictureIsLoadingSelector
};
