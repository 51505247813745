import mixpanel from 'mixpanel-browser';

const { MIXPANEL_TOKEN } = process.env;

const actions = {
  init: () => {
    if (MIXPANEL_TOKEN) {
      mixpanel.init(MIXPANEL_TOKEN);
    }
  },
  identify: id => {
    if (MIXPANEL_TOKEN) {
      mixpanel.identify(id);
    }
  },
  setPeople: props => {
    if (MIXPANEL_TOKEN) {
      mixpanel.people.set(props);
    }
  },
  register: props => {
    if (MIXPANEL_TOKEN) {
      mixpanel.register(props);
    }
  },
  track: (name, props) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.track(name, props);
    }
  },
};

export const Mixpanel = actions;
