const baseProduct = ({ countryValues = {} } = {}) => ({
  price: { value: null },
  currency: { value: null },
  resourceType: { value: 'challenge' },
  identifier: { value: null },
  inventory: {
    type: { value: 'finite' },
    quantity: { value: null },
  },
  title: { ...countryValues },
  description: { ...countryValues },
  shortDescription: { ...countryValues },
  customerInformation: { value: [] },
  image: {
    url: null,
    file: null,
  },
  connectedAccount: { value: null },
  applicationFee: { value: null },
});

export default baseProduct;
