import { ChallengesApi } from '@sportheroes/oly-engine-api';

import { olyEngineApi } from './Config/olyEngineApi';

const { CORE_API_URL } = process.env;

export default new ChallengesApi(
  CORE_API_URL,
  {
    fromAdmin: true,
  },
  olyEngineApi,
);
